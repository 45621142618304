import { isAndroid, isIOS, isChrome, isBrowser, osName, browserName, mobileModel, deviceDetect } from 'react-device-detect';
import windowSize from 'react-window-size';
import { builtinModules } from 'module';

let isMobile = false;

// all iphones
if (mobileModel.indexOf('iPhone') >= 0) { isMobile = true; }

// all Mac OS devices (iPads) < 1280
if ((osName.indexOf('Mac') >= 0 || osName.indexOf('iOS') >= 0) && window.innerWidth < 1280) { isMobile = true; }

// all android devices
if (osName.indexOf('Android') >= 0) { isMobile = true; }

// Amazon or very large tablets break mobile mode
if (browserName.indexOf('Silk') >= 0 || window.innerWidth >= 1280) { isMobile = false; }

const isTablet = isMobile && window.innerWidth >= 600;

export { isMobile, isTablet }