import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faPhoneAlt, faEnvelope, faComments } from '@fortawesome/free-solid-svg-icons'

import HowItWorks from '../components/messages/HowItWorks';

import { ACCEPT_NEW_PROFILES } from '../../constants';

import '../pages/css/gethelp.css';

class Donate extends Component {

    render() {
        return (
            <div className="">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Request Assistance</h2>
                            <div id="bolded-line"></div>
                            <HowItWorks classNameOverride="button how-it-works-button-get-help float-right color" />
                        </div>
                    </div>
                </div>
                {
                    ACCEPT_NEW_PROFILES ?

                        <div className="container">
                            <div className="sixteen columns center">
                            </div>
                            <div className="one-third column">
                                <div className="get-help-choice-box" onClick={() => { window.location = '/post-profile?from=get-help' }}>
                                    <h3 className="center">Request Assistance</h3>
                                    <p className="center get-help-icon">
                                        <FontAwesomeIcon icon={faUserCircle} size="6x" />
                                    </p>
                                    <h5 className="center show-if-large">Create a profile detailing the assistance you need.</h5>
                                    <h5 className="center show-if-small">Create a profile<br />detailing the assistance you need.</h5>
                                    <p className="center italic small-margin-top">For example:<br />Food &bull; Donations &bull; Errands &bull; Transportation</p>
                                    <p className="center">Reach a community looking for opportunities to give.</p>
                                    <p className="center"><button className="button color">Request assistance</button></p>
                                </div>
                            </div>
                            <div className="one-third column">
                                <div className="get-help-choice-box" onClick={() => { window.location = 'sms://+18883192221' }}>
                                    <h3 className="center">Call or Text</h3>
                                    <p className="center get-help-icon">
                                        <FontAwesomeIcon icon={faPhoneAlt} size="4x" />
                                    </p>
                                    <h2 className="center get-help-contact show-if-large"><p className="small-text get-help-contact-number center ">Toll free in the US<br />10am-5pm PST Mon-Friday</p> (888) 319-2221</h2>
                                    <h4 className="center get-help-contact show-if-small"><p className="small-text get-help-contact-number center ">Toll free in the US<br />10am-5pm PST Mon-Friday</p> (888) 319-2221</h4>
                                    <h5 className="center show-if-large">Speak with a Community Care agent.</h5>
                                    <h5 className="center show-if-small">Speak with a<br />Community Care agent.</h5>
                                    <p className="center">Our team will work with you by phone or text to create a profile for a community of givers.{/* or help you to find resources near you */}</p>
                                </div>
                            </div>
                            <div className="one-third column">
                                <div className="get-help-choice-box" onClick={() => { window.location = 'mailto:help@bridgeofkindness.org' }}>
                                    <h3 className="center">Email</h3>
                                    <p className="center get-help-icon">
                                        <FontAwesomeIcon icon={faEnvelope} size="4x" />
                                    </p>
                                    <h3 className="center get-help-contact show-if-large"><a href='ma&#105;l&#116;&#111;&#58;help&#64;%6&#50;%&#55;2%69d&#37;67e&#111;f&#107;%69&#110;dn%&#54;&#53;%73%73&#46;com'>care&#64;&#98;r&#105;dgeofkindness&#46;c&#111;m</a></h3>
                                    <h5 className="center get-help-contact show-if-small"><a href='ma&#105;l&#116;&#111;&#58;help&#64;%6&#50;%&#55;2%69d&#37;67e&#111;f&#107;%69&#110;dn%&#54;&#53;%73%73&#46;com'>care&#64;<br />&#98;r&#105;dgeofkindness&#46;c&#111;m</a></h5>
                                    <h5 className="center show-if-large">Take your time. Tell us your story.</h5>
                                    <h5 className="center show-if-small">Take your time.<br />Tell us your story.</h5>
                                    <p className="center">We get it.  Asking for support can be difficult.  Work with our team by email to create a profile outlining your needs. {/* or help you to find resources near you */}</p>
                                </div>
                            </div>
                            <div className="clear"></div>
                            <div className="sixteen columns">
                                <div className="center large-margin-top show-if-large"><h5>Not sure where to start?&nbsp;&nbsp;<span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}><FontAwesomeIcon icon={faComments} size="lg" /></span>&nbsp;<span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}>Click here</span> to chat with a Community Care team member!</h5></div>
                                <div className="center large-margin-top show-if-small"><h5>Need help?<br /><span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}><FontAwesomeIcon icon={faComments} size="lg" /></span>&nbsp;<span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}>Click here</span> to chat with a Community Care team member!</h5></div>
                                <div className="center italic get-help-terms">By registering for, accessing, and using this website, you agree to all terms set forth in our <a href='/terms' target="_blank" className="underline">website terms of use</a>. </div>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="sixteen columns">
                                <p>We appreciate your interest in creating a profile requesting a need.</p>
                                <p>To maximize the effectiveness of our activity for profiles, we limit the total number of active profiles on our platform.</p>
                                <p>Due to reaching the limit of profiles, new profiles are not being added at this time.</p>
                                <p>However, please consider adding yourself to our waiting list <a href="/newsletter" className="underline">here</a>.  Members of our waiting list will be notified as availability for profiles on our platform become availabile.</p>
                                <p></p>
                                <p>We look forward to working with you in the future!</p>
                                <p>&nbsp;</p>
                                <button className="button color" onClick={() => { window.location = '/newsletter' }}>Join Waiting List</button>
                            </div>
                        </div>
                }
            </div >)
    }
}
export default Donate;