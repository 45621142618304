import React from 'react';

function Alert(props) {
    return (
        <div className={`notification ${props.class} `}>
            {props.icon ? <i className={`fa ${props.icon}`}></i> : ''}
            <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
            {/* <a className="close" href="#"></a> */}
        </div>
    )
}

export default Alert;