import React, { Component } from 'react';

class ErrorProfile extends Component {
  render() {
    return (
      <div className="container" >
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Invalid Page</h2>
              <div id="bolded-line"></div>
            </div>

          </div>
        </div>
        <div className="container">
          <div className="eight columns">
            <h4>Sorry, the profile link you requested is invalid.</h4>
            <p><a href='/view-profile'>View Profiles</a></p>
            <p><a href='/'>Return to Home Page</a></p>
          </div>
        </div>
      </div>
    );
  }
}
export default ErrorProfile;
