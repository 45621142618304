import React, { Component } from "react";
import { isMobile } from "../components/utils/Mobile";

import "../pages/css/about.css";

class About extends Component {
  constructor(props) {
    super(props);

    this.GA = props.GA;
  }

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Our team</h2>
              <div id="bolded-line"></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="three columns">
            <img className="team-pic" src="/images/smcdonald.png"></img>
            <h4 className="center small-margin-bottom light-padding">
              Founder/CEO
            </h4>
          </div>
          <div className="thirteen columns">
            <div className="margin-left">
              <p>
                <span className="bold">Steve McDonald</span> is an author and a software engineer with over 22 years of experience.  In
                response to the global COVID-19 pandemic, he conceived of an
                idea to build a website that aims to connect those who are in
                need with people who have the resources to help. His goal and
                passion is to uplift communities and spreading kindness,
                modeling the 'Good Samaritan' story in the Bible - Luke
                10:25-37.
              </p>
              <p>
                Steve has gone on to create and launch Bridge of Kindness,
                creating a platform and organization that promotes kindness and
                pursues opportunity to help others in need.
              </p>
              {isMobile ? (
                ""
              ) : (
                <ul style={{ zIndex: 0 }} className="social-icons">
                  <li>
                    <a
                      className="linkedin"
                      target="_blank"
                      href="https://www.linkedin.com/in/hstevemcdonald/"
                    >
                      <i className="icon-linkedin"></i>
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div className="margin-left"></div>
          </div>
        </div>
        <p></p>
        <div className="container">
          <div className="three columns">
            <img className="team-pic" src="/images/jjames-2024.jpg"></img>
            <h4 className="center small-margin-bottom light-padding">
              Program Director
            </h4>
          </div>
          <div className="thirteen columns">
            <div className="margin-left">
              <p>
                <span className="bold">Dr. Jennifer James</span> is an English
                professor, Researcher, and published scholar. After 27 years of
                teaching and helping others acquire knowledge, a close family
                member of hers became unsheltered. From that point on, she began
                helping her community by volunteering to teach reading and
                prepare and serve food to individuals who are unsheltered.
              </p>
              <p>
                Her specialty now is in spreading happiness and kindness to
                those who are most in need of it. Dr. James is a passionate
                mother, teacher, and mentor and she helps our organization by
                managing our outreach programs and community missions.
              </p>
              {isMobile ? (
                ""
              ) : (
                <ul style={{ zIndex: 0 }} className="social-icons">
                  <li>
                    <a
                      className="linkedin"
                      target="_blank"
                      href="https://www.linkedin.com/in/drjljames/"
                    >
                      <i className="icon-linkedin"></i>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
