import React from "react";
import { isMobile } from "../utils/Mobile";
import Social from "../../components/social/Social";
import { slide as Menu } from "react-burger-menu";

import "../../pages/css/header.css";
import "../../pages/css/mobile-menu.css";

function _isPath(path) {
  return window.location.href.endsWith(path);
}

function _includesPath(path) {
  return window.location.href.indexOf(path) > 0;
}

function Header(props) {
  return (
    <div>
      <div className="show-if-small">
        <div className="mobile-header">
          <span align="center">
            <a href="/">
              <img src="/images/mobile-header.png" />
            </a>
          </span>
          <div className="">
            <Menu
              burgerButtonClassName={"mobile-menu-button"}
              className={"mobile-menu"}
              overlayClassName={"mobile-menu-overlay"}
            >
              <a id="home" href="/" className="menu-item">
                Home
              </a>
              <a id="about" href="/missions" className="menu-item">
                Missions
              </a>
              {/* <a id="menu-post-profile" href="/request-assistance" className="menu-item">Request assistance</a> */}
              {/* <a id="view-profiles" href="/view-profile" className="menu-item">View profiles</a> */}
              {/* <a id="volunteer" href="https://join.bridgeofkindness.org?f=bok" target="_join" className="menu-item">Volunteer with us</a> */}
              {/* <a id="volunteer" href="/volunteer" className="menu-item">
                Volunteer with us
              </a> */}
              <a id="volunteer" href="https://blog.bridgeofkindness.org/jobs" className="menu-item">
                Volunteer opportunities
              </a>
              {/* <a id="faq" href="/faq" className="menu-item">FAQs</a> */}
              <a id="contact" href="/contact" className="menu-item">
                Contact us
              </a>
              <a
                id="sign-up-newsletter"
                href="/newsletter"
                className="menu-item"
              >
                Sign up for newsletter
              </a>
              {/* <a id="my-profile" href="/manage-profile" className="menu-item">My Profile</a> */}
              <a id="about" href="/about" className="menu-item">
                Our story
              </a>
              <a id="about" href="/team" className="menu-item">
                Our team
              </a>
            </Menu>
          </div>
        </div>
        <div className="mobile-header-gap"></div>
      </div>
      <div className="container ie-dropdown-fix show-if-large">
        {/* <!-- Header --> */}
        <div id="header">
          {/* <!-- Logo --> */}
          <div className="eight columns">
            <div id="logo">
              <div>
                <a href="/">
                  <img
                    src={
                      _includesPath("logo2")
                        ? "/images/bok-logo.png"
                        : "/images/bok-logo2.png"
                    }
                    alt="Bridge of Kindness"
                    className={
                      isMobile ? "bok-logo-header-mobile" : "bok-logo-header"
                    }
                  />
                </a>
              </div>

              <div>
                <div className={isMobile ? "bok-title-mobile" : "bok-title"}>
                  <div
                    className={
                      isMobile
                        ? "bok-logo-text-top-mobile"
                        : "bok-logo-text-top"
                    }
                  >
                    Bridge of Kindness
                  </div>
                  <div className="headline bok-logo-text-line">&nbsp;</div>
                  <div className="bok-logo-text-bottom">
                    Compassion. Contribution. Community.
                  </div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>

          {/* <!-- Social / Contact --> */}
          {isMobile ? (
            ""
          ) : (
            <div className="eight columns float-right">
              <Social top={true}></Social>
            </div>
          )}
        </div>
        {/* <!-- Header / End --> */}

        {/* <!-- Navigation --> */}
        <div className="sixteen columns">
          <div id="navigation">
            <ul id="nav">
              <li>
                <a
                  id={_includesPath("/missions") ? "current" : ""}
                  href="/missions/"
                >
                  Missions
                </a>
                <ul>
                <li>
                    <a class="long-nav" href="https://blog.bridgeofkindness.org/spring-2024-mission-tigermountain-foundation/">Spring 2024 - TigerMountain Foundation</a>
                  </li>
                  <li>
                    <a class="long-nav" href="https://blog.bridgeofkindness.org/winter-2023-mission-happy-acres/">Winter 2023 - Happy Acres</a>
                  </li>
                </ul>
              </li>
              <li><a href="https://blog.bridgeofkindness.org/category/blog/">Blog</a></li>

              {/* <li><a id={_includesPath('/request-assistance') ? "current" : ""} href="/request-assistance">Request Assistance</a></li> */}
              <li>
                <a
                  id={
                    _includesPath("/view-profile") || _includesPath("/support")
                      ? "current"
                      : ""
                  }
                  className=""
                  href="#"
                >
                  Give Kindness
                </a>
                {/* <a id={_includesPath('/view-profile') || _includesPath('/support') ? "current" : ""} className="" href="/view-profile" >Give Kindness</a> */}
                <ul>
                  {/* <li><a href="/view-profile" className="long-nav">View profiles of persons in need</a></li> */}
                  <li>
                    {/* <a href="/volunteer">Volunteer with us</a> */}
                    <a href="https://blog.bridgeofkindness.org/jobs">Volunteer opportunities</a>
                  </li>
                  <li>
                    <a href="/support" className="long-nav">
                      Donate to Bridge of Kindness
                    </a>
                  </li>
                </ul>
              </li>

              {/* <li><a id={_includesPath('/volunteer') ? "current" : ""} href="/faq">FAQs</a></li> */}
              <li>
                <a
                  id={
                    _includesPath("/contact") || _includesPath("/newsletter")
                      ? "current"
                      : ""
                  }
                  href="/contact"
                >
                  Contact
                </a>
                <ul>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                  <li>
                    <a href="/newsletter">Sign up for newsletter or alerts</a>
                  </li>
                </ul>
              </li>
              <li>
                <a id={_includesPath("/about") ? "current" : ""} href="/about">
                  About
                </a>
                <ul>
                  <li>
                    <a href="/about">Our story</a>
                  </li>
                  <li>
                    <a href="/team">Our team</a>
                  </li>                 
                </ul>
              </li>
            </ul>

            {/* <!-- Search Form --> */}
            {/* <ul className="my-profile-button">
                            <li><a id={_includesPath('/manage-profile') ? "current" : ""} href="/manage-profile">My Profile</a></li>
                        </ul> */}

            <div className="search-form">
              {/* 
                        <form method="get" action="#">
                            <input type="text" className="search-text-box" />
                        </form> */}
            </div>
          </div>
          <div className="clear"></div>
        </div>
        {/* <!-- Navigation / End --> */}
      </div>
    </div>
  );
}

export default Header;
