import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import axios from 'axios';

class Activate extends Component {
  constructor(props) {
      super(props);
      this.state = {
          accessKey: null,
          redirect: null
      }
      this.redirect = this.redirect.bind(this);
      this.redirect(props);
  }

  redirect(props) {
    const _this = this;
    const { accessKey } = props.match.params;
    axios.post('/api/activate', { accessKey })
        .then(function (response) {
        if (response.data.success) {
            _this.setState({..._this.state, redirect: `/manage-profile/${accessKey}/confirmed`});
        } else {
            _this.redirectError('ac-e2');
        }})
        .catch(function (error) {
            _this.redirectError('ac-e3');
        });
  }

  render() {
    if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
    }
    return <div></div>
  }
}

export default Activate;