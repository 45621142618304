import React from 'react';

function Image (props) {
  return (
    <img       id={props.id}
    src={props.src}  alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" className="rev-slidebg" data-no-retina/>
  )
}

export default Image;
