import React, { Component } from 'react';
import { isMobile } from '../components/utils/Mobile'

import '../pages/css/about.css';

class About extends Component {
    constructor(props) {
        super(props)

        this.GA = props.GA;

    }

    render() {
        return (
            <div className="container">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Our story</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sixteen columns">
                        <div className="margin-left">
                            <p>Originally conceived as an idea in response to the global COVID-19 pandemic, San Francisco East Bay local <span className="bold underline"><a href="/team">Steve McDonald</a></span> launched Bridge of Kindness (BOK) as a mission arm of <a className="underline" href="https://walkinginstepwithgod.org">Walking in Step with God Ministries</a> in September 2023. Our organization aims to connect those who are in need with people who have the resources to help with the goal of uplifting communities and spreading kindness.</p>
                            {/* <blockquote>
                                "We must work together to provide an extraordinary response for those in need.<br /><br /> Bridge of Kindness was created to provide an immediate response for COVID-19 and for anyone in need well into the future."  - Steve McDonald
                            </blockquote> */}
                            {/* <p>Steve has a passion for connecting people and making a positive impact during the current crisis and situations happening in our nation. Steve believes that kindness comes in all forms, whether large or small. It all counts. </p> */}
                            <blockquote>
                                "Bridge of Kindness is a platform inspired by
                                {/* <span> my experiences helping a stranger while out and about in the world.  For example, helping to change a tire on the side of the road or handing my just purchased lunch to someone with a sign that says they need food.  It has also been a blessing to be helped by someone when I needed it most.  <br></br><br></br>These experiences mirror </span> */}
                                the power of kindness as told in the parable of the 'Good Samaritan' in scripture - Luke 10:25-37.<br></br><br></br>Bridge of Kindness aims to provide a space for one stranger to offer kindness to another."  - Steve McDonald
                            </blockquote>
                            <p>Bridge of Kindness is a non-profit organization funded by the founding team and donations from the community.  Our mission is to show compassion to those that require it most, our neighbors, our community and those in need. </p>
                            <p>Interested in helping to support our cause? <a className="underline" href="/contact">Contact us</a> to learn more or <a className="underline" href="/support">donate</a> to provide financial support for our mission.</p>

                            <h5>#Wereinthistogether</h5>
                            <br /><br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;