
import React from 'react';
import { isMobile, isTablet } from '../utils/Mobile';
import Map from '../../components/profile/Map';
import TextDetailView from '../../components/profile/TextDetailView'
import { NEED_OPTIONS } from '../../../constants';

import '../../pages/css/profile-view.css'

// a bit hacky, but..
function ifAnyNeeds(profile) {
  let result = false;
  for (const need in profile) {
    if (need.indexOf('need') === 0 && profile[need] === true) {
      result = true;
    }
  }
  return result;
}

function ViewProfile(props) {
  const { profile, nextStep, previousStep, slim } = props;
  const options = NEED_OPTIONS;


  if (!profile || !profile.email) {
    return (<div></div>)
  }

  const AnyNeedsBox = () => {
    {
      return ifAnyNeeds(profile) ?
        <div className="notification small-notification-padding warning">
          <ul className="list-style-square-needs small-text">
            {profile.needDonationFinancial ? <li>Needs financial assistance</li> : ''}
            {profile.needDonationFoodSupplies ? <li>Needs food and/or supplies</li> : ''}
            {profile.needVolunteers ? <li>Needs volunteer(s)</li> : ''}
            {profile.needErrands ? <li>Needs assistance with errand(s)</li> : ''}
            {profile.needLawnCare ? <li>Needs lawn care</li> : ''}
            {profile.needPetDonation ? <li>Needs pet donation</li> : ''}
            {profile.needTransportation ? <li>Needs transportation</li> : ''}
            {profile.needMentalSupport ? <li>Needs emotional support</li> : ''}
          </ul>
        </div> : ''
    }
  }

  const LocationBox = () => {
    {
      return profile.locationText && profile.location && profile.location.coordinates && profile.location.coordinates.length && profile.location.coordinates[0] && profile.location.coordinates[1] ?
        <div>
          <div className="margin-top large-notice show-if-small profile-map-alert margin-bottom" >
            <div className="small-text">Location:</div>
            <div className="small-text"><h6>{profile.locationText}</h6></div>
            <div className="clear"></div>
          </div >
          <div className="margin-top large-notice show-if-large  profile-map-alert margin-bottom" >
            <div className="small-text">Location:</div>
            <div className="small-text"><h6>{profile.locationText}</h6></div>
            <Map center={{ lat: profile.location.coordinates[1], lng: profile.location.coordinates[0] }} zoom={11} />
            <div className="small-text profile-map-controls show-if-large">Ctrl + mouse wheel to zoom</div>
            <div className="clear"></div>
          </div >
        </div > : ""
    }
  }

  const DonationBox = (props) => {
    {
      return profile.venmo || profile.paypal || profile.cashapp ?
        <div className={props.slim ? "margin-top" : "margin-top margin-bottom large-notice financial-need-box "}>
          <div className={props.slim ? "bold margin-bottom" : "small-text"}>Accepting financial assistance:</div>
          {profile.venmo ?
            <div className="margin-top">
              <img className="profile-donate-venmo" src="/images/venmo.png"></img>
              <h6>{profile.venmo}</h6>
            </div>
            : ""}
          {profile.cashapp ?
            <div className="margin-top">
              <img className="profile-donate-cashapp" src="/images/cashapp.png"></img>
              <h6>{profile.cashapp}</h6>
            </div>
            : ""}
          {profile.paypal ?
            <div className="margin-top">
              <img className="profile-donate-paypal" src="/images/paypal.png"></img>
              <h6>{profile.paypal}</h6>
            </div>
            : ""}
        </div> : ""
    }
  }
  return (
    <div className={slim ? 'five columns' : 'ten columns'}>
      <div className={slim ? '' : 'five columns preview-profile'}>
        <div className="preview-profile-inner">
          <h3 className="meet-text">Meet <b>{profile.firstName} {profile.lastInitial}.</b></h3>
          {profile.need ? <div className="margin-top"><h5 className="small-margin-bottom">About Me/Introduction</h5>
            {profile.need.trim().split('\n').map((item, key) => {
              return <div key={key}>{item}<br /></div>
            })}</div> : ''}
          <TextDetailView profile={profile} tag="needDonationFinancial" separator={true} />
          <TextDetailView profile={profile} tag="needDonationFoodSupplies" separator={true} />
          <TextDetailView profile={profile} tag="needVolunteers" separator={true} />
          <TextDetailView profile={profile} tag="needErrands" separator={true} />
          <TextDetailView profile={profile} tag="needLawnCare" separator={true} />
          <TextDetailView profile={profile} tag="needPetDonation" separator={true} />
          <TextDetailView profile={profile} tag="needTransportation" separator={true} />
          <TextDetailView profile={profile} tag="needMentalSupport" />
        </div>
      </div>
      {slim ?
        <div className="preview-profile-inner">
          {profile.locationText ? <div >
            <div className="bold margin-bottom">Location:</div>
            {profile.locationText && <div className="small-intro-text">{profile.locationText}</div>}
          </div> : ''}
          <div className="clear"></div>
          {profile.venmo || profile.paypal || profile.cashapp ? <div >
            <DonationBox slim={slim} />
          </div> : ''}
        </div>
        :
        <div className="three columns view-profile-detail-column">
          {isMobile && !isTablet ? <span><p></p><p></p></span> : ''}
          <AnyNeedsBox />
          <LocationBox />
          <DonationBox />
        </div>
      }
      {nextStep ? <div className="margin-top">
        <br /><p className="margin-top"><button className="button wizard-button-back" onClick={previousStep}>Back</button><button className="button wizard-button margin-left" onClick={nextStep}>Complete profile</button></p>
      </div> : ''}
    </div>

  );
}

export default ViewProfile
