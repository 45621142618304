import React, { Component } from 'react';

import Social from '../social/Social';
import { isMobile } from '../utils/Mobile';

class Footer extends Component {

    render() {


        return (
            <div id="footer" >
                <div className="container">

                    <div className="five columns">
                        <div className="footer-headline"><h3>About Us</h3></div>
                        <p>Our mission is to show compassion to those that require it most, our neighbors, our community and those in need. </p>
                        <p>What makes us different...</p>
                        <p>Donations can be made to numerous organizations, but not all donations are alike. Bridge of Kindness not only offers the opportunity to make a donation, but also encourages neighborly acts of kindness that help people endure the harsh realities of life.</p>

                        {isMobile ?
                            <div className="margin-top">
                                <div className="footer-headline"><h3>Contact Us</h3></div>
                                <Social left={true}></Social>
                            </div>
                            : ''}
                    </div>

                    <div className="one column">
                        <div className="one column">
                            &nbsp;
			</div>
                    </div>
                    

                    <div className="four columns">
                        {/* <div className="footer-headline"><h3><i className="fa fa-instagram"></i> Photo Stream</h3></div> */}
                        {/* <ul id="instagram"></ul> */}
                        &nbsp;
                    </div>
                    <div className="five columns">
                        <div className="footer-headline"><h3>Useful Links</h3></div>
                        <ul className="links-list">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">Our story</a></li>
                            <li><a href="/team">Our team</a></li>
                            {/* <li><a href="/post-profile">Request assistance</a></li> */}
                            {/* <li><a href="/view-profile">View profiles</a></li> */}
                            <li><a href="/contact">Contact</a></li>
                            {/* <li><a href="/faq">FAQs</a></li> */}
                            {/* <li><a href="https://join.bridgeofkindness.org?f=bok" target="_join">Volunteer with us</a></li> */}
                            {/* <li><a href="/volunteer">Volunteer with us</a></li> */}
                            <li><a href="https://blog.bridgeofkindness.org/jobs">Volunteer opportunities</a></li>
                            <li><a href="/support">Donate</a></li>
                            <li><a href="/newsletter">Sign up for newsletter or alerts</a></li>
                            <li><a href="/terms">Terms and conditions</a></li>
                            <li><a href="/privacy">Privacy policy</a></li>
                            <li><a href="/privacy-safety">Privacy & Safety Guidelines</a></li>
                        </ul>
                    </div>
                    <div className="clear"></div>
                    {/* 
                <div className="four columns">
                    <div className="footer-headline"><h4>Latest Tweets</h4></div>
                    <ul id="inst"></ul>
                    <div className="clear"></div>
                </div> */}

                    <div className="sixteen columns">
                        <div id="footer-bottom">
                            © Copyright 2023 by Bridge of Kindness. All rights reserved.
			</div>
                    </div>

                </div>

            </div >
        );
    }
}

export default Footer;
