import React from 'react';
import { Input } from 'formsy-react-components';
import scrambleAutoComplete from '../../utils/Scramble';
import Steps from './Steps';

function ProfileFormCategory({ profile, nextStep, previousStep, previousButton, nextButton, isMobile, componentRefs }) {
    return (
        <div className="seven columns">
            <label className="col-form-label">Provide details for how you can receive donations:</label>
            <div className="two columns">
                <img className="large-margin-top" src='/images/cashapp.png' style={{ 'marginTop': '42px' }} />
            </div>
            <div className="four columns">
                <Input componentRef={(node) => { componentRefs(node, 'cashapp') }} onFocus={el => scrambleAutoComplete(el)} label="Cash App ID (optional)" value={profile.cashapp} className="text pay-text-field" type="text" validationError="Please enter a valid Cash App ID" validations="minLength:3" placeholder="Cash App ID" name="cashapp" />
            </div>
            <div className="two columns">
                <img className="large-margin-top" src='/images/venmo.png' style={{ 'marginTop': '48px' }} />
            </div>
            <div className="four columns">
                <Input componentRef={(node) => { componentRefs(node, 'venmo') }} onFocus={el => scrambleAutoComplete(el)} label="Venmo ID (optional)" value={profile.venmo} className="text pay-text-field" type="text" validationError="Please enter a valid Venmo ID" validations="minLength:3" placeholder="Venmo ID" name="venmo" />
            </div>
            <div className="two columns">
                <img className="large-margin-top" src='/images/paypal.png' style={{ 'marginTop': '35px' }} />
            </div>
            <div className="four columns">
                <Input componentRef={(node) => { componentRefs(node, 'paypal') }} onFocus={el => scrambleAutoComplete(el)} label="PayPal ID (optional)" value={profile.paypal} className="text pay-text-field" type="email" validationError="Please enter a valid email" validations="isEmail,minLength:3" placeholder="PayPal ID" name="paypal" />
            </div>
            <div className="clear"></div>
            {nextStep ? <div className=""><br />
                <p className="margin-top"><button type="button" className="button wizard-button-back" onClick={previousButton}>Back</button><button type="button" className="button wizard-button margin-left" onClick={nextButton}>Continue</button></p>
            </div> : ''}
        </div>)
}

export default ProfileFormCategory;