

import React from 'react';

function Steps({ step, setWizardStep }) {
    return <ul class="progressbar margin-bottom">
        <li onClick={() => setWizardStep(1)} className={step === 1 ? 'active' : ''}>About You</li>
        <li onClick={() => setWizardStep(2)} className={step === 2 ? 'active' : ''}>The Need</li>
        <li onClick={() => setWizardStep(3)} className={step === 3 ? 'active' : ''}>Details</li>
        <li onClick={() => setWizardStep(6)} className={step === 4 ? 'active' : ''}>Finish</li>
    </ul>
}

export default Steps;

