module.exports = {
    ACCEPT_NEW_PROFILES: false || process.env.REACT_APP_ACCEPT_NEW_PROFILES,
    MAPS_API_KEY: process.env.REACT_APP_MAPS_API_KEY,
    INSTAGRAM_ACCESS_TOKEN: process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN,
    MAX_TEXTAREA_SIZE: 1000,
    MAX_NAME_LENGTH: 64,
    SHOW_PHONE: process.env.REACT_APP_SHOW_PHONE || false,
    SHOW_CHAT: process.env.REACT_APP_SHOW_CHAT || false,
    NEW_PROFILE_ALERTS: process.env.REACT_APP_NEW_PROFILE_ALERTS || false,
    FACEBOOK_PIXEL_ID: process.env.FACEBOOK_PIXEL_ID,
    FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
    GA_TRACKING: process.env.NODE_ENV === 'production' ? 'UA-163978686-1' : 'UA-163978686-2',
    MISSION_TEXT: "Bridge of Kindness helps to connect persons in need with a community seeking opportunities to give.",
    NEED_OPTIONS: {
        needDonationFinancial: [
            { value: 'FINLAIDOFF', label: 'I was laid off' },
            { value: 'FINUNDEREMPLOYED', label: 'I am underemployed' },
            { value: 'FINCOMPCLOSED', label: 'My company closed' },
            { value: 'FINOWNCOMPCLOSED', label: 'Had to close my own company' },
            { value: 'FINNOGOVTRELIEF', label: 'I don\'t qualify for government relief' },
            { value: 'FINOTHER', label: 'Other' },
        ],
        needDonationFoodSupplies: [
            { value: 'FOODNEEDED', label: 'I am in need of food and/or household goods' },
            { value: 'FOODSHOP', label: 'I need assistance shopping for food' },
            { value: 'FOODSHOPHOUSEHOLD', label: 'I need assistance shopping for household goods' },
            { value: 'FOODSHOPMEDS', label: 'I need assistance with medical supplies/medications' },
            { value: 'FOODOTHER', label: 'Other' },
        ],
        needErrands: [
            { value: 'ERRANDPET', label: 'I need assistance with my pet(s)' },
            { value: 'ERRANDHOUSE', label: 'I need assistance with household cleaning/maintenance' },
            { value: 'ERRANDMAIL', label: 'I need assistance with mail pickup' },
            { value: 'ERRANDOTHER', label: 'Other' },
        ]
    },
    NEED_CATEGORIES: {
        'needDonationFinancial': {
            label: 'Financial assistance'
        },
        'needErrands': {
            label: 'Help with errands'
        },
        'needDonationFoodSupplies': {
            label: 'Food/Groceries'
        },
        'needVolunteers': { label: 'Volunteer(s)' },
        'needLawnCare': { label: 'Help with lawn care' },
        'needPetDonation': { label: 'Help with a pet donation' },
        'needTransportation': { label: 'Transportation' },
        'needMentalSupport': { label: 'Emotional support' }
    }
    ,
    QUOTES: [
        // { q: "“Covid-19 has introduced an extraordinary level of suffering upon humanity. We must rise up with an extraordinary response for those in need.”", a: "Steve McDonald" },
        { q: "“We must rise up with an extraordinary response for those in need.”", a: "Steve McDonald" },
        { q: "And do not forget to do good and to share with others, for with such sacrifices God is pleased.", a: "Hebrews 13:16 " },
        { q: "“Selfless giving is the art of living.”", a: "Frederick Lenz" },
        { q: "“Bridge of Kindness is a platform inspired by the parable of the good samaritan in scriptures Luke 10:25-37.  I wanted to provide a space for one stranger to show kindness to another.”", a: "Steve McDonald" },
        { q: "Give, and it will be given to you.", a: "Luke 6:38 " },
        { q: "“We rise by lifting others.”", a: "Robert Ingersoll" },
        { q: "“I have experienced many occasions to meet a stranger in need and offer assistance in a number of ways.  I believe these experiences were a blessing to me and a blessing to the individual.”", a: "Steve McDonald" },
        { q: "Give to the one who asks you, and do not turn away from the one who wants to borrow from you.", a: "Matthew 5:42" },
        { q: "“Giving is not just about making a donation.It is about making a difference.”", a: "Kathy Calvin" },
        { q: "“We must act to lift one another up in their time of need.”", a: "Steve McDonald" },
        { q: "Do not withhold good from those to whom it is due, when it is your power to act", a: "Proverbs 3:27" },
        { q: "“No one has ever become poor by giving.”", a: "Anne Frank" },
        { q: "“We aim to build a framework that will remain over time a resource and a meeting place between persons in need and persons who can give.”", a: "Steve McDonald" },
        // { q: "“Bridge of Kindness was created during the Covid-19 pandemic as a response to unprecedented suffering across the world.  We aim to build a framework that will remain over time a resource and a meeting place between persons in need and persons who can give.”", a: "Steve McDonald" },
        { q: "The generous will themselves be blessed, for they share their food.", a: "Proverbs 22:9" },
        { q: "“Giving is the master key to success, in all applications of human life.”", a: "Bryant McGill" }
    ]

}
