import React, { Component } from 'react';
import HomeLanding from '../components/home/HomeLanding';
import Slider from '../components/home/Slider';
import { isMobile, isTablet } from '../components/utils/Mobile';

class Home extends Component {
  render() {
    return (
      <HomeLanding>{!isMobile && !isTablet ? <Slider /> : ''}</HomeLanding>
    );
  }
}

export default Home
