import React, { Component } from "react";
import { isMobile } from "../components/utils/Mobile";

import "../pages/css/volunteer.css";

class Volunteer extends Component {
  constructor(props) {
    super(props);
    this.GA = props.GA;
  }

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Volunteer with us</h2>
              <div id="bolded-line"></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="sixteen columns">
            <div className="margin-left">
              <p>
              If you resonate with our mission and are eager to make a positive impact, we warmly invite you to join us as a volunteer. As a volunteer, you will have the opportunity to contribute in a variety of ways, from participating in our monthly missions to becoming an advocate for those in need in your own community. Your time, skills, and compassion can make a tangible difference in the lives of those we serve, while also presenting an invaluable opportunity for personal growth.
              </p>
          

              <p>
                <strong>Remote/Virtual volunteers are needed in the following areas:</strong>
              </p>
              <ul className="list-style-square">
                <li>
                  Secretarial assistance
                </li>
                <li>
                  Outreach communication
                </li>
                <li>
                  Event coordination and assistance
                </li>
                <li>
                  Data entry
                </li>
              </ul>
              <p>
                Additionally, volunteer assistance is needed in-person for missions and events in the <strong>Phoenix, AZ metropolitain area</strong>.
              </p>
              <p>
                    To learn more about volunteer opportunities and how you can get involved in our missions, please don't hesitate to <a className="underline" href="/contact">contact us</a>. Whether you have a few hours each month or can contribute on a more regular basis, every effort is vital in strengthening our community. Join us at Bridge of Kindness, and together, let's create a world where kindness is a bridge to a brighter future..
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Volunteer;
