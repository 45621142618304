
import React, { Component } from 'react';
import { Form, Select, Textarea, Checkbox, Input } from 'formsy-react-components';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faEnvelope, faSearch, faExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import Alert from '../messages/Alert';
import SpreadTheWord from '../social/SpreadTheWord';

import '../../pages/css/geosuggest.css'
import '../../pages/css/profile-view.css';
import { isMobile, isTablet } from '../utils/Mobile';

import { MISSION_TEXT } from '../../../constants';

const google = window.google;

class ProfileSearch extends Component {

    constructor(props) {
        super(props)
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onBlurCheckLocation = this.onBlurCheckLocation.bind(this);
        this.redirectError = this.redirectError.bind(this);
        this.submitAdvancedSearch = this.submitAdvancedSearch.bind(this);
        this.notifyAdvancedSearchFormError = this.notifyAdvancedSearchFormError.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
        this.onSuggestSelect = this.onSuggestSelect.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.setConfirmed = this.setConfirmed.bind(this);
        this.setShowSendMessageModal = this.setShowSendMessageModal.bind(this);
        this.setShowHideContactFields = this.setShowHideContactFields.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.setCopiedState = this.setCopiedState.bind(this);

        // props
        this.setSimpleSearch = props.setSimpleSearch;
        this.getNextProfile = props.getNextProfile;
        this.getBackProfile = props.getBackProfile;
        this.GA = props.GA;
        this.ReactPixel = props.ReactPixel;

        this.fromText = null;
        this.subjectText = null;
        this.phoneText = null;
        this.emailText = null;
        this.messageText = null;

        this.state = {
            apiError: '',
            copied: false,
            messageApiError: '',
            profile: this.props.profile,
            locationError: null,
            simpleSearch: props.simpleSearch,
            locationText: '',
            maxRadiusTypeDefault: 'm',
            maxRadiusSizeDefault: '5',
            showContactFields: false,
            sendMessageModal: false,
            locationText: this.profile && this.profile.locationText ? this.profile.locationText : '',
            location: {
                lat: this.profile && this.profile.location && this.profile.location.coordinates ? this.profile.location.coordinates[1] : 0,
                lng: this.profile && this.profile.location && this.profile.location.coordinates ? this.profile.location.coordinates[0] : 0
            }
        };
    }

    setShowHideContactFields(element) {
        const _this = this;
        _this.setState({ ..._this.state, showContactFields: !_this.state.showContactFields });
    }

    scrambleAutoComplete(element) {
        element.target.setAttribute('autocomplete', Math.random())
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    redirectError(error = 'ac-e1') {
        const _this = this;
        _this.setState({ ..._this.state, redirect: `/get-profile/${error}` });
    }

    onChangeLocation(location) {

        const _this = this;

        const changeObject = { ..._this.state, locationError: null, locationText: location };
        if (location !== _this.state.validResult) {
            changeObject.location = { lat: 0, lng: 0 }
            changeObject.validResult = '';
            changeObject.maxRadiusTypeDefault = 'm';
            changeObject.maxRadiusSizeDefault = '5';
        }
        _this.setState(changeObject);
    }

    setShowSendMessageModal(showModal) {
        const _this = this;
        _this.setState({ ..._this.state, messageApiError: '', showSendMessageModal: showModal, showContactFields: false });
        if (showModal) {
            _this.GA.logModal('view-profile/compose-mesage')
        }

    }

    setCopiedState(status = true) {
        const _this = this;
        _this.setState({ ..._this.state, copied: status });
    }

    onBlurCheckLocation(el) {
        const _this = this;

        // if we are good, then exit
        if (el === _this.state.validResult) {
            return false;
        }

        const { location } = _this.state;
        const locationError = (location.lat === 0 || location.lng === 0) && el ? '<div>When providing a location, please select from the options provided below.</div>' : null;
        setTimeout(() => {
            _this._geoSuggest.update(el);
            _this._geoSuggest.focus();
            _this.setState({ ..._this.state, locationError, locationText: el, location: { lat: 0, lng: 0 } });
        }, 300);

    }

    // populate state of location when selected
    onSuggestSelect(suggest) {
        if (!suggest || !suggest.label) {
            return false;
        }
        const _this = this;
        _this.setState({ ..._this.state, validResult: suggest.label, locationError: null, locationText: suggest.label, location: suggest.location, });
    }

    submitMessage(message, resetForm, invalidateForm) {
        const _this = this;

        if (!message.message) {
            _this.setMessageError("Please enter text for your message", true);
            return;
        }

        if (!message.from || !message.from.length) {
            delete (message.from);
        }

        if (!message.subject || !message.subject.length) {
            delete (message.subject);
        }

        if (!message.email || !message.email.length) {
            delete (message.email);
        }

        if (!message.phone || !message.phone.length) {
            delete (message.phone);
        }

        let messageText = message.message;
        if (message.phone) {
            messageText += `\n\nContact phone number: ${message.phone}`
        }

        if (message.email) {
            messageText += `\n\nContact email: ${message.email}`
        }

        // determine post or patch
        const request = {
            url: '/api/message',
            method: 'post',
            data: {
                from: message.from,
                fromEmail: message.email || "NONE",
                subject: message.subject,
                message: messageText,
                viewAccessKey: _this.state.profile.viewAccessKey
            }
        }

        axios(request)
            .then(function (result) {
                _this.setConfirmed(`Message sent to ${_this.state.profile.firstName}!`);
                resetForm();
                _this.setShowSendMessageModal(false);
                _this.GA.logEvent({ category: 'View Profile', action: 'Message Sent' });

                _this.ReactPixel.track('track', { 'page': 'View Profile-0', 'action': 'SendMessage' });
                _this.ReactPixel.trackCustom('track', { 'page': 'View Profile-1', 'action': 'SendMessage' });
                _this.ReactPixel.track('View Profile-2', { 'action': 'SendMessage' });
                _this.ReactPixel.fbq('track', 'View Profile-3', { action: 'SendMessage' })
                _this.ReactPixel.fbq('trackCustom', 'View Profile-4', { action: 'SendMessage' })
            })
            .catch(function (error) {
                console.log("Error", error)
                _this.setError('Error submitting message', true);
            }).finally(() => { });
    }

    setConfirmed(message) {
        const _this = this;
        if (message) {
            _this.setState({ ..._this.state, confirmedMessage: message });
            setTimeout(() => {
                _this.setState({ ..._this.state, confirmedMessage: '' });
            }, 5000)
        } else {
            _this.setState({ ..._this.state, confirmedMessage: '' });
        }
    }

    setMessageError(error, fade) {
        const _this = this;
        _this.setState({ ..._this.state, messageApiError: error });
        if (fade) {
            setTimeout(() => {
                _this.setState({ ..._this.state, messageApiError: '' });
            }, 5000)
        }
    }

    setError(error, fade) {
        const _this = this;
        _this.setState({ ..._this.state, apiError: error });
        if (fade) {
            setTimeout(() => {
                _this.setState({ ..._this.state, apiError: '' });
            }, 5000)
        }
    }

    submitAdvancedSearch(filter) {
        const _this = this;
        const { lat, lng } = _this.state.location;
        filter.locationText = _this.state.locationText;
        filter.location = {
            type: 'Point',
            coordinates: [lng, lat]
        }
        _this.getNextProfile(filter);
        _this.setCopiedState(false);
    }

    notifyAdvancedSearchFormError(data, resetForm, invalidateForm) {
        const _this = this;
        let errs = {}
        _this.scrollToTop();
        invalidateForm(errs)
    }

    handleScriptLoad = () => {
        const _this = this;
        // Declare Options For Autocomplete
        const options = {
            types: ['(cities)'],
            rankby: 'distance'
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('location'),
            options,
        );

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(['address_components', 'formatted_address', 'name', 'geometry']);

        // Fire Event when a suggested name is selected

        this.autocomplete.addListener('place_changed', _this.handlePlaceSelect);
    }

    handlePlaceSelect = () => {
        const _this = this;

        const addressObject = this.autocomplete.getPlace();
        const { formatted_address, geometry } = addressObject;

        _this.setState({
            ..._this.state,
            validResult: typeof formatted_address === "string" ? formatted_address : '',
            locationError: null,
            locationText: typeof formatted_address === "string" ? formatted_address : '',
            location: { lat: geometry.location.lat(), lng: geometry.location.lng() }
        });
    }

    componentDidUpdate() {
        const _this = this;

        // update state for search when triggered by parent or this component
        if (this.props.simpleSearch !== this.state.simpleSearch) {
            _this.setState({ ..._this.state, simpleSearch: this.props.simpleSearch });
        }

        if (this.props.profile && this.props.profile.email !== this.state.profile.email) {
            _this.setState({ ..._this.state, profile: this.props.profile });
        }

    }

    componentDidMount() {
        const _this = this;
        _this.handleScriptLoad();
        _this._isMounted = true;
        window.onpopstate = () => {
            if (this._isMounted) {
                _this.getBackProfile()
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        
        const { copied, profile, getNextProfileNow, showSendMessageModal, showContactFields, locationError, messageApiError, apiError, confirmedMessage, simpleSearch, maxRadiusTypeDefault, maxRadiusSizeDefault, locationText } = this.state;
        const actualLocationText = typeof locationText === "string" ? locationText : '';

        if (this.props.getNextProfileNow) {
            this.getNextProfile();
            return <div></div>
        }

        const { self, GA } = this.props;

        const withinDistanceOptions = [
            { value: '5', label: 'Within 5' },
            { value: '15', label: 'Within 15' },
            { value: '25', label: 'Within 25' },
            { value: '50', label: 'Within 50' },
            { value: '100', label: 'Within 100' },
            { value: '200', label: 'Within 200' },
            { value: '500', label: 'Within 500' }
        ];

        const typeDistanceOptions = [
            { value: 'm', label: 'miles' },
            { value: 'k', label: 'kilometers' },
        ];

        return (
            <div className="">
                {profile.viewAccessKey ?
                    <div className="five columns">
                        {apiError ? <div>{GA.logEvent({ category: 'View Profile', action: 'API Error', label: apiError })}<Alert class='warning' text={apiError} /></div> : ''}
                        {confirmedMessage ? <div>{GA.logEvent({ category: 'View Profile', action: 'Confirmed', label: confirmedMessage })}<Alert class='success' text={confirmedMessage} /></div> : ''}
                        <button disabled={self} onClick={() => this.setShowSendMessageModal(true)} className={isTablet ? "button large block profile-action-button-tablet" : "button large block profile-action-button"}><i className="fa fa-envelope"></i> Send a message to {profile.firstName}</button>
                        <div className="small-text small-margin-top "><a href='/contact' className="report-abuse">Report abuse</a></div>
                        <br />
                        <div className="center">
                            <h3><i className="fa fa-bullhorn bullhorn"></i> Share {profile.firstName}'s profile</h3>
                            <SpreadTheWord text={`Please help me to assist ${profile.firstName}.\n\n${MISSION_TEXT}\n\nFollow the link to view ${profile.firstName}'s profile page: \n\n`} shortText={`Please help me to assist ${profile.firstName} at Bridge of Kindness`} url={window.location.href} size={32} />
                            <CopyToClipboard text={window.location.href}
                                onCopy={() => this.setCopiedState()}>
                                {copied ? <button className="button clipboard-copy color"><i className="fa fa-copy"></i> URL copied to clipboard!</button> : <button className="button clipboard-copy"><i className="fa fa-copy"></i> Copy page URL to clipboard</button>}
                            </CopyToClipboard>
                        </div>
                        <div className="headline headline-small-margin">&nbsp;</div><br />

                    </div>
                    : ""
                }
                <div className={simpleSearch ? 'five columns' : 'display-none'}>
                    {GA.logModal('view-profile/simple-search')}
                    <button disabled={self} type="button" className={isTablet ? "button color large block profile-action-button-tablet" : "button color large block profile-action-button"} onClick={() => { this.getNextProfile(); this.setCopiedState(false) }}><FontAwesomeIcon icon={faSearch} size="1x" /> {profile.email ? 'View next profile' : 'View profiles'}</button>
                    <div className="form-group">
                        <br /><a href="#switchToAdvanced" onClick={() => this.setSimpleSearch(false)}><span className="underline">Switch</span> to advanced search</a>
                    </div>
                    {isMobile && !isTablet ? '' :
                        <div>
                            <br /> <br /> <br /> <br />
                            <div className="notification warning">
                                <div >
                                    <FontAwesomeIcon icon={faExclamationCircle} size="4x" pull="left" />
                                </div>
                                <div className="nudge-margin-top">
                                    <p><a href='/privacy-safety'><span className="underline">Be Safe.</span></a> Follow guidelines to be safe when communicating with others.</p>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="headline headline-margin-bottom">&nbsp;</div>
                            <div className="notification notice">
                                <div >
                                    <FontAwesomeIcon icon={faInfoCircle} size="4x" pull="left" />
                                </div>
                                <div className="nudge-margin-top">
                                    <p>Support <span className="bold">Bridge of Kindness</span> to assist people in need. <a href='/support'><span className="underline">Learn more</span></a></p>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="headline headline-margin-bottom">&nbsp;</div>
                            <div className="notification success">
                                <div >
                                    <FontAwesomeIcon icon={faEnvelope} size="4x" pull="left" />
                                </div>
                                <div className="nudge-margin-top">
                                    <p><a href='/newsletter'><span className="underline">Sign up</span></a> for our newsletter
                            to stay informed of news and updates.</p>
                                </div>
                                <div className="clear"></div>
                            </div>

                        </div>}

                </div>
                <div className={simpleSearch ? 'display-none' : 'five columns'}>
                    <Form
                        onValidSubmit={this.submitAdvancedSearch}
                        onInvalidSubmit={this.notifyAdvancedSearchFormError}
                        noValidate>
                        {GA.logModal('view-profile/advanced-search')}
                        <div>
                            <label className="">Specify your desired location of the need: (optional)</label>
                            {locationError ? <Alert className="warning" text={locationError} /> : ''}
                            <Input onFocus={el => this.scrambleAutoComplete(el)} onBlur={el => this.onBlurCheckLocation(el, this)}
                                onChange={el => this.onChangeLocation(el)} onKeyUp={el => this.onChangeLocation(el)} className="long-text-field" type="text" name="locationText" id="location" placeholder="City, State" hinttext="" value={actualLocationText} />
                            <h6>
                                <small className="text-muted">Maximum distance from location:</small>
                            </h6>

                            <div className="">
                                <Select className="float-left" disabled={self || !this.state.locationText}
                                    layout="vertical"
                                    name="maxRadiusSize"
                                    value={maxRadiusSizeDefault}
                                    options={withinDistanceOptions}
                                />

                                <Select className="margin-left float-left" disabled={self || !this.state.locationText}
                                    layout="vertical"
                                    name="maxRadiusType"
                                    value={maxRadiusTypeDefault}
                                    options={typeDistanceOptions}
                                />
                            </div>

                            <div className="clear"></div>
                        </div>

                        <label className="col-form-label">Speficy categories of need: (optional)</label>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Financial assistance" name="needDonationFinancial" id="needDonationFinancial"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Food/Groceries" name="needDonationFoodSupplies" id="needDonationFoodSupplies"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Volunteer(s)" name="needVolunteers" id="needVolunteers"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Help with errand(s)" name="needErrands" id="needErrands"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Lawn care" name="needLawnCare" id="needLawnCare"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Pet donation" name="needPetDonation" id="needPetDonation"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Transportation" name="needTransportation" id="needTransportation"></Checkbox>
                        <Checkbox disabled={self} layout="elementOnly" rowClassName="override-label-width" valueLabel="Emotional support" name="needMentalSupport" id="needMentalSupport"></Checkbox>
                        <div className="clear"></div>

                        <div className="margin-top row">
                            <button disabled={self} type="submit" className="button color large block profile-action-button"><FontAwesomeIcon icon={faSearch} size="1x" /> {profile.email ? 'View next profile' : 'View profiles'}</button>
                            <div className="form-group">
                                <br /><a href="#switchToAdvanced" onClick={() => this.setSimpleSearch(true)}><span className="underline">Switch</span> to simple search</a>
                            </div>
                        </div>
                    </Form>
                </div>
                <Modal
                    center={true}
                    showCloseIcon={true}
                    open={showSendMessageModal}
                    onClose={() => this.setShowSendMessageModal(false)}>
                    <h2>Send a message</h2>
                    <Form onSubmit={this.submitMessage}>
                        {messageApiError ? <Alert class='margin-top warning' text={messageApiError} /> : ''}
                        <div>
                            <div className="two columns">
                                <Input onFocus={el => this.scrambleAutoComplete(el)} id="subject" autoComplete="off" ref={el => this.subjectText = el} className="long-text-field" validations="isAlpha" label="Subject (optional)" layout="vertical" name="subject" disabled={self} placeholder="Subject for message.." />
                            </div>
                            <div className="two columns">
                                <Input onFocus={el => this.scrambleAutoComplete(el)} id="from" ref={el => this.fromText = el} className="long-text-field" validations="isAlpha" label="Contact name (optional)" layout="vertical" name="from" disabled={self} placeholder="From Name/Organization" />
                            </div>
                            <div className="two columns margin-top">
                                <Checkbox onClick={() => { this.setShowHideContactFields() }} rowClassName="override-label-width-no" valueLabel="I'd like to share my contact info." name="showContactFields" id="showContactFields"></Checkbox>
                            </div>
                            {showContactFields &&
                                <div>
                                    <div className="two columns">
                                        <Input type="text" onFocus={el => this.scrambleAutoComplete(el)} id="phoneText" autoComplete="off" ref={el => this.phoneText = el} className="long-text-field" validations="isAlpha" label="Contact phone number (optional)" layout="vertical" name="phone" placeholder="(301) 555-1212" />
                                    </div>
                                    <div className="two columns">
                                        <Input type="email" onFocus={el => this.scrambleAutoComplete(el)} id="emailText" autoComplete="off" ref={el => this.emailText = el} className="long-text-field" validations="isEmail" label="Contact email (optional)" layout="vertical" name="email" placeholder="joe@smith.com" />
                                    </div>
                                </div>
                            }
                            <div className="twp columns max-width-message-modal">
                                <Textarea className="margin-top" id="message" value="" layout="vertical" name="message" disabled={self} cols="40" rows="5" placeholder={`A message for ${profile.firstName}...`} />
                                <h6 className="textarea-hint small-margin-top">
                                    Tips:
                                    <ul className="list-style-square">
                                        <li><a href='/privacy-safety' target="_blank"><span className="underline">Be Safe.</span></a> Follow guidelines to be safe when communicating with others.
                                        </li>
                                        {!showContactFields && <li>Be sure to provide your contact information if you request a reply.</li>}
                                    </ul>
                                </h6>
                            </div>
                        </div>
                        <div className="float-right margin-top">
                            <button disabled={self} id="submit" type="submit" className="button color">Send message to {profile.firstName}</button>
                            <button type="button" className="margin-left button light" onClick={() => this.setShowSendMessageModal(false)}>Cancel</button>
                        </div>
                    </Form>

                </Modal>
            </div >
        );
    }

}

export default ProfileSearch;
