import React, { Component } from 'react';
import axios from 'axios'
import MetaTags from 'react-meta-tags';
import shortUUID from 'short-uuid';
import ProfileView from '../components/profile/ProfileView';
import ProfileSearch from '../components/profile/ProfileSearch';
import scrollToTop from '../components/utils/ScrollToTop';

import { Redirect } from 'react-router-dom';
import { isMobile, isTablet } from '../components/utils/Mobile';

const short = shortUUID();

class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
    this.redirectError = this.redirectError.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.getNextProfile = this.getNextProfile.bind(this);
    this.setSimpleSearch = this.setSimpleSearch.bind(this);
    this.getBackProfile = this.getBackProfile.bind(this);
    this.continueProfiles = this.continueProfiles.bind(this);

    this.GA = props.GA;
    this.ReactPixel = props.ReactPixel;
 

    this.state = {
      viewAccessKey: props.match.params || null,
      preview: false,
      profile: {},
      simpleSearch: true,
      filterNotFound: null,
      isReady: false,
      history: {},
      lastProfile: null,
      getNextProfileNow: props.getNextProfileNow
    }
  }

  redirect(url) {
    const _this = this;
    _this.setState({ ..._this.state, redirect: url });
  }

  redirectError(error = 'e1') {
    const _this = this;
    _this.setState({ ..._this.state, redirect: `/error-profile/${error}` });
  }

  continueProfiles() {
    const _this = this;
    _this.props.history.push('/p/' + _this.state.lastProfile);
    _this.setState({ ..._this.state, isReady: false, lastProfile: null });
    _this.loadProfile();
  }

  loadProfile() {
    const _this = this;
    const { viewAccessKey, self } = this.props.match.params;
    if (viewAccessKey) {
      const fetchViewAccessKey = short.toUUID(viewAccessKey)
      axios.get(`/api/viewprofile/${fetchViewAccessKey}`)
        .then(function (response) {
          if (response.data) {
            _this.setState({ ..._this.state, isReady: true, profile: response.data, viewAccessKey, self });
          } else {
            _this.redirectError('vp-e1');
          }
        })
        .catch(function (error) {
          _this.redirectError('vp-e1');
        });
    } else {
      _this.setState({ ..._this.state, isReady: true });
    }
  }

  getBackProfile() {
    const _this = this;
    _this.loadProfile();
  }

  getNextProfile(filter) {
    const _this = this;

    // if locationText is blank, delete it
    if (filter && filter.hasOwnProperty('locationText') && filter.locationText === '') {
      delete (filter.locationText);
    }

    // if location is passed we have a filter
    const filterQuery = filter && filter.hasOwnProperty('location') ? filter : {};

    axios.post(`/api/nextprofile/`, filterQuery)
      .then(function (response) {
        if (response.data) {
          const shortId = short.fromUUID(response.data.viewAccessKey);
          const filterNotFound = response.data.email ? null : filterQuery;
          let history = _this.state.history;
          let profile = response.data;
          let lastProfile = null;
          if (_this.state.history[shortId]) {
            history = {};
            lastProfile = shortId;
            profile = {}
          } else {
            history[shortId] = 1;
            _this.GA.logEvent({ category: 'View Profile', action: 'View', label: response.data.viewAccessKey });
            _this.props.history.push('/p/' + shortId);
          }
          _this.setState({ ..._this.state, getNextProfileNow: false, lastProfile, history, filterNotFound, profile, viewAccessKey: response.data.viewAccessKey });
          scrollToTop();
        } else {
          _this.redirectError('mp-e2');
        }
      })
      .catch(function (error) {
        _this.redirectError('mp-e3');
      });
  }

  setSimpleSearch(status) {
    const _this = this;
    _this.setState({ ..._this.state, simpleSearch: status });
  }

  // when we come in as confirmed, show confirmed
  componentDidMount() {
    const _this = this;
    _this.loadProfile()
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { profile, self, history, lastProfile, filterNotFound, simpleSearch, isReady, getNextProfileNow } = this.state;
    const { GA, ReactPixel } = this.props;

    const needs = filterNotFound ? Object.keys(filterNotFound).reduce((totalNeeds, val) => {
      return val.indexOf('need') === 0 && filterNotFound[val] ? totalNeeds + 1 : totalNeeds
    }, 0) : 0;

    const Header = () => (<div className="five columns">
      {!getNextProfileNow && <div className="headline no-margin margin-top"><h3>View profiles</h3></div>}
      <br />   <br />
      <MetaTags>
        <title>{title}</title>
        <meta id="meta-description" name="description" content={metaDescription} />
        <meta id="meta-title" property="og:title" content={title} />
        <meta id="meta-twitter-card" name="twitter:card" content={metaDescription} />
        <meta id="meta-property-description" property="og:description" content={metaDescription} />
      </MetaTags>
    </div>)

    const title = `Bridge of Kindness - Meet ${profile.firstName} ${profile.lastInitial}.`;
    const metaDescription = profile.need ? profile.need.length > 100 ? profile.need.substring(0, 100) : profile.need : '';

    return (
      <div>

        <div className="container">
          {self ?
            <div className="sixteen columns">
              <div className="notification success preview-bar-height">
                <h5 className="float-left">Preview Mode</h5> &nbsp; <button type="button" className="notice-button-adjust button small-button light float-right" style={{ marginRight: '10px' }} onClick={() => this.redirect(`/manage-profile/`)} >Return to my profile</button>
              </div>
            </div>
            : ''}
          {!isReady ?
            <div className="margin-top"><img className="loader" src='/images/loader.gif'></img></div>
            :
            <div className="sixteen columns">
              { !getNextProfileNow && <div className="nine columns view-profile-spacer">
                <br />
                {
                  profile.email ?
                    <ProfileView
                      GA={GA}
                      profile={profile}
                      getNextProfile={this.getNextProfile}
                      getBackProfile={this.getBackProfile} />
                    :
                    filterNotFound ?
                      <div className="large-notice">
                        <div>
                          {filterNotFound.locationText || needs > 0 ? <h3 className="card-title">No profiles were found matching your selected filter(s):</h3> : <h3 className="card-title">No profiles were found.</h3>}
                          {filterNotFound.locationText ? <p><br />Within <b>{filterNotFound.maxRadiusSize} {filterNotFound.maxRadiusType === 'm' ? 'Miles' : 'Kilometers'}</b> of <b>{filterNotFound.locationText}</b></p> : ''}
                          {needs > 0 ? needs === 1 ? <p>Required:</p> : <p>At least one required:</p> : ''}
                          <ul className="list-style-square">
                            {filterNotFound.needDonationFinancial ? <li>Needs financial assistance</li> : ''}
                            {filterNotFound.needDonationFoodSupplies ? <li>Needs food/groceries</li> : ''}
                            {filterNotFound.needVolunteers ? <li>Needs volunteer(s)</li> : ''}
                            {filterNotFound.needErrands ? <li>Needs assistance with errand(s)</li> : ''}
                            {filterNotFound.needLawnCare ? <li>Needs lawn care</li> : ''}
                            {filterNotFound.needPetDonation ? <li>Needs pet donation</li> : ''}
                            {filterNotFound.needTransportation ? <li>Needs transportation</li> : ''}
                            {filterNotFound.needMentalSupport ? <li>Needs emotional support</li> : ''}
                          </ul>
                          <br />
                          <h6>Please try again with different search criteria or <a href='#' onClick={() => { this.setSimpleSearch(true); this.getNextProfile() }}>use <span className="underline">simple search</span></a>.</h6>
                        </div>
                      </div>

                      :
                      lastProfile ?
                        <div>
                          <div className="large-notice">
                            <h5 className="view-profile-welcome">Thank you!</h5>
                            <p className="margin-top"><span className="bold">Thank you for viewing the profiles of persons in need of assistance.</span></p>
                            <h5>What's next?</h5>
                            <br />
                            <button className="button color" onClick={() => this.continueProfiles()}>Continue viewing profiles</button>
                            <p className="small-margin-top margin-bottom">Review available profiles again and look for opportunties to give kindness.</p>
                            <button className="button color" onClick={() => window.location.href = '/newsletter'}>Sign up for new profile alerts</button>
                            <p className="small-margin-top">Sign up to receive an email digest of newly created profiles daily or weekly.</p>
                          </div>
                          <br />
                        </div>
                        : isMobile && !isTablet ?

                          <div ><Header /><h5 className="view-profile-welcome">Welcome!</h5>
                            <p className="margin-top"><span className="bold">Your action can enhance the quality of life of someone in your community…</span><br /><br /></p>
                          </div> : <div className="large-notice">
                            <div className="four columns">
                              <h5 className="view-profile-welcome">Welcome!</h5>
                              <p className="margin-top"><span className="bold">Your action can enhance the quality of life of someone in your community…</span></p>
                              <p><i>Kindness</i> can come in many forms such as dropping off groceries on someone’s doorstep, a message of encouragement, a donation for their needs or maybe walking their dog.</p>
                            </div>
                            <div className="three columns">
                              {/* <img className="light-border view-profile-image" src='/images/stockphotos/help_someone.jpg' /> */}
                              <img style={{ marginTop: '45px', width: '225px' }} src="/images/stockvectors/heart.png" />
                            </div>
                            <div className="eight columns">

                              <p><span class="italic">Any act of kindness</span> that we can show one another, helps us lift each other up. </p>
                              <h5>Simple Search - How it works:</h5>
                              <ul className="list-style-square">
                                <li>Click <span className="bold">View next profile</span></li>
                                <li>Review profiles until you find a good match</li>
                                <li>Send a message to connect with the owner of the profile</li>
                              </ul>
                              <br />
                              <h5>Advanced Search - How it works:</h5>
                              <ul className="list-style-square">
                                <li>Click <span className="bold">Switch to advanced search</span></li>
                                <li>Specify any desired category or location filters to match profiles</li>
                                <li>Click <span className="bold">View next profile</span></li>
                                <li>Review profiles until you find a good match</li>
                                <li>Send a message to connect with the owner of the profile</li>
                              </ul>
                              <br />
                              <p><span className="italic">Be sure to include contact information if you would like a response!</span></p>
                            </div>
                            <div className="clear"></div>

                          </div>
                }
              </div>}
              <div className="five columns">
                {isMobile && !isTablet ? '' : <Header />}
                <ProfileSearch
                  ReactPixel={ReactPixel}
                  GA={GA}
                  profile={profile}
                  getNextProfile={this.getNextProfile}
                  getBackProfile={this.getBackProfile}
                  getNextProfileNow={getNextProfileNow}
                  setSimpleSearch={this.setSimpleSearch}
                  simpleSearch={simpleSearch}
                  self={self}
                />
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default ViewProfile;
