import React, { Component } from 'react';
import { addValidationRule } from 'formsy-react';
import { Form } from 'formsy-react-components';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import StepWizard from 'react-step-wizard';
import transitions from '../../../pages/css/profile-wizard/wizard.less'
import '../../../pages/css/profile-wizard/fix.css'; // fix z-index issue

import '../../../pages/css/form-steps.css';

import { isMobile } from '../../utils/Mobile'

import { MAX_TEXTAREA_SIZE, NEED_CATEGORIES } from '../../../../constants'

import Alert from '../../messages/Alert';
import ProfileFormContact from './ProfileFormContact';
import ProfileFormDetail from './ProfileFormDetail';
import ProfileFormStart from './ProfileFormStart';
import ProfileFormCategory from './ProfileFormCategory';
import ProfileFormFinancial from './ProfileFormFinancial';
import ProfileFormIntro from './ProfileFormIntro';

import ProfileFormSubmit from './ProfileFormSubmit';
import Steps from './Steps';

addValidationRule('textAreaRequired', function (values, value) {
    return typeof value !== 'undefined' && value !== '' && value !== false && value.length < MAX_TEXTAREA_SIZE + 1;
});

class ProfileForm extends Component {
    constructor(props) {
        super(props);
        // do -not - specify checkboxes in blank
        const blank = {
            firstName: '',
            lastInitial: '',
            email: '',
            locationText: '',
            zipCode: '',
            need: '',
        }

        // const blank = {
        //     firstName: 'Susan',
        //     lastInitial: 'C',
        //     email: 'dd@dd.com',
        //     locationText: 'Hayward, CA, USA',
        //     need: 'Some need sjlkjsd ljsd flkasjd fljsdljsd fljsd fljsdf ljsdf lasjdflajsdlkajsd flajsdf lasdj lsadj flasdjf lajsd lsdjlsdj lsd k',
        //     venmo: 'venmoid',
        // }

        // props from parent
        this.copyProps = props;
        this.mode = props.mode;
        this.updateStep = props.updateStep;
        this.profile = props.profile ? props.profile : blank;
        this.profileHeaderText = props.profileHeaderText;
        this.accessKey = props.setAccessKey;
        this.redirectError = props.redirectError;
        this.GA = props.GA;
        this.ReactPixel = props.ReactPixel;

        this.scrollToTop = this.scrollToTop.bind(this);
        this.onBlurCheckLocation = this.onBlurCheckLocation.bind(this);
        this.notifyFormError = this.notifyFormError.bind(this);
        this.setError = this.setError.bind(this);
        this.submit = this.submit.bind(this);
        this._submitProfile = this._submitProfile.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.redirect = this.redirect.bind(this);
        this.checkNeedLength = this.checkNeedLength.bind(this);
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.scrambleAutoComplete = this.scrambleAutoComplete.bind(this);
        this.textBox = this.textBox.bind(this);
        this.setupProfileForm = this.setupProfileForm.bind(this)
        this.checkFields = this.checkFields.bind(this)
        this.componentRefs = this.componentRefs.bind(this);
        this.setWizardInstance = this.setWizardInstance.bind(this);
        this.setWizardStep = this.setWizardStep.bind(this);
        this.checkDetails = this.checkDetails.bind(this);
        this.checkPayment = this.checkPayment.bind(this);

        this.state = {
            apiError: '',
            confirmedMessage: '',
            locationError: null,
            validResult: '',
            step: 1,
            wizardStep: 1,
            locationText: this.profile && this.profile.locationText,
            location: {
                lat: this.profile && this.profile.location && this.profile.location.coordinates ? this.profile.location.coordinates[1] : 0,
                lng: this.profile && this.profile.location && this.profile.location.coordinates ? this.profile.location.coordinates[0] : 0
            },
            transitions: {
                enterRight: `${transitions.animated} ${transitions.enterRight}`,
                enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
                exitRight: `${transitions.animated} ${transitions.exitRight}`,
                exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
                intro: `${transitions.animated} ${transitions.intro}`,
            },
            confirmed: props.confirmed,
            mode: props.mode,
            needLength: props.profile && props.profile.need && props.profile.need.length || 0,
            showText: {}
        };

        // timer control
        this.timer = null;

        // formsy
        this.formsyhoc = null;

        // component keys
        this.formKeys = {}
    }

    scrambleAutoComplete(element) {
        element.target.setAttribute('autocomplete', Math.random())
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    setError(error) {
        const _this = this;
        _this.setState({ ..._this.state, apiError: error });
    }

    setConfirmed(message) {
        const _this = this;
        if (message) {
            _this.setState({ ..._this.state, confirmedMessage: message });
            _this.timer = setTimeout(() => {
                _this.setState({ ..._this.state, confirmedMessage: '' });
            }, 5000)
        } else {
            _this.setState({ ..._this.state, confirmedMessage: '' });
        }
    }

    submit(profile) {

        const _this = this;
        const { lat, lng } = _this.state.location;
        profile.email = profile.email.toLowerCase();

        profile.locationText = _this.state.locationText;
        profile.location = {
            type: 'Point',
            coordinates: [lng, lat]
        }

        _this._submitProfile(profile);
    }

    // https://medium.com/@hamzaqaisrani/using-the-google-maps-places-autocomplete-javascript-api-in-a-react-project-5742bab4abc9
    handleScriptLoad = () => {
        const _this = this;
        // Declare Options For Autocomplete
        const options = {
            types: ['(cities)'],
            rankby: 'distance'
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('location'),
            options,
        );

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(['address_components', 'formatted_address', 'name', 'geometry']);

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', _this.handlePlaceSelect);
    }

    handlePlaceSelect = () => {
        const _this = this;


        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const { formatted_address, geometry } = addressObject;

        console.log(addressObject)

        _this.setState({
            ..._this.state,
            validResult: addressObject && addressObject.address_components && addressObject.address_components.length ? addressObject.address_components[0].long_name : false,
            locationError: null,
            locationText: typeof formatted_address === "string" ? formatted_address : '',
            location: { lat: geometry.location.lat(), lng: geometry.location.lng() }
        });
    }

    _submitProfile(profile) {
        const _this = this;

        // check if at least one checkbox has been checked
        const regex = /^need[A-Z]/g;
        if (!Object.keys(profile).some(key => key.match(regex) !== null && profile[key] === true)) {
            _this.setError('Please select at least one category of need.');
            _this.scrollToTop();
            return;
        }

        // force Last name to initial
        profile.lastInitial = profile.lastInitial[0];

        // delete any empty need text
        const textRegex = /^text[A-Z]/g;
        for (const need in profile) {
            if (need.match(textRegex) && typeof profile[need] != 'undefined' && !profile[need].length) {
                delete (profile[need])
            }
        }

        if (profile.email) {
            profile.email = profile.email.toLowerCase();
        }

        if (profile.terms) {
            delete (profile.terms)
        }

        if (!profile.venmo || !profile.venmo.length) {
            delete (profile.venmo);
        }

        if (!profile.cashapp || !profile.cashapp.length) {
            delete (profile.cashapp);
        }

        if (!profile.paypal || !profile.paypal.length) {
            delete (profile.paypal);
        }

        if (typeof profile.locationText != "string" || !profile.locationText || !profile.locationText.length) {
            delete (profile.locationText);
        }

        if (typeof profile.zipCode != "string" || !profile.zipCode || !profile.zipCode.length) {
            delete (profile.zipCode);
        }

        if (!profile.need || !profile.need.length) {
            delete (profile.need);
        }

        if (profile.needDonationFinancial && !(profile.paypal || profile.cashapp || profile.venmo)) {
            _this.scrollToTop();
            _this.setWizardStep(4, 3);
            _this.setError('Please provide a Venmo, PayPal or Cash App id to receive donations.');
            return;
        }

        // determine post or patch
        const request = this.mode === 'new' ?
            {
                url: '/api/profile',
                method: 'post'
            } : {
                url: `/api/profile/${_this.accessKey}`,
                method: 'patch'
            }
        request.data = profile;

        axios(request)
            .then(function () {
                if (_this.mode === 'new') {
                    _this.updateStep(2);
                    _this.GA.logEvent({ category: 'Post Profile', action: 'CompleteNewProfile' });
                    _this.ReactPixel.fbq('trackCustom', 'New Profile', { action: 'CompleteNewProfile' })
                } else {
                    _this.setConfirmed('Profile has been updated.')
                }
                _this.scrollToTop();
            })
            .catch(function (error, response) {
                const errorData = error.response.data;
                let errorMessage = `Error creating profile: ${errorData.error}. Please contact help@bridgeofkindness.org for assistance.`;
                if (errorData.duplicateError) {
                    errorMessage = errorData.error;
                    if (errorData.wizardStep) {
                        _this.setWizardStep(errorData.wizardStep, errorData.uiStep);
                    }
                }
                _this.setError(errorMessage);
                _this.scrollToTop();
            })
    }

    redirect(url) {
        const _this = this;
        _this.setState({ ..._this.state, redirect: url });
    }

    notifyFormError(data, resetForm, invalidateForm) {
        const _this = this;
        let errs = {}
        _this.scrollToTop();
        invalidateForm(errs)
    }

    onChangeLocation(location) {
        const _this = this;

        const changeObject = { ..._this.state, locationError: null, locationText: location };
        if (location !== _this.state.validResult) {
            changeObject.location = { lat: 0, lng: 0 }
            changeObject.validResult = '';
        }
        _this.setState(changeObject);
    }

    onBlurCheckLocation(el) {
        const _this = this;

        // if we are good, then exit
        if (el === _this.state.validResult) {
            return false;
        }

        const { location } = _this.state;
        const locationError = (location.lat === 0 || location.lng === 0) && el ? '<div>When providing a location, please select from the options provided below.</div>' : null;
        setTimeout(() => {
            if (_this._geoSuggest) {
                _this._geoSuggest.update(el);
                _this._geoSuggest.focus();
                _this.setState({ ..._this.state, locationError, locationText: el, location: { lat: 0, lng: 0 } });
            }
        }, 300);
    }

    checkNeedLength(el) {
        const _this = this;
        const needLength = el.target.value.length;
        _this.setState({ ..._this.state, needLength });
    }

    textBox(el, tag) {
        const _this = this;
        const status = el.target.checked;
        const { showText } = _this.state;
        showText[tag] = status;
        _this.setState({ ..._this.state, showText });
    }

    // when we come in as confirmed, show confirmed
    componentDidMount() {
        const _this = this;
        if (_this.state.confirmed) {
            _this.setConfirmed('Your profile is now activated!');
        }
        _this.handleScriptLoad()
        _this.setupProfileForm();
    }

    componentWillUnmount() {
        const _this = this;
        if (_this.timer) {
            clearTimeout(_this.timer);
        }
    }


    setupProfileForm() {
        const _this = this;
        const regex = /^need[A-Z]/g;
        const thisState = _this.state;
        if ('email' in _this.profile) {
            Object.keys(_this.profile).forEach(key => {
                if (key.match(regex)) {
                    thisState.showText[key] = _this.profile[key]
                }
            })
        }
    }

    validationErrors(el) {
        console.log(el)
    }

    checkNeeds(wizardStep, uiStep) {
        const _this = this;
        if (!Object.keys(NEED_CATEGORIES).some(key => _this.formKeys['ref_' + key] && _this.formKeys['ref_' + key].props.value === true)) {
            _this.setWizardStep(2);
            _this.setError('Please select at least one category of need.');
            return;
        }
        _this.setWizardStep(wizardStep, uiStep);
    }

    checkPayment(step = 5) {
        const _this = this;
        let pass = true;

        const paymentOptions = ['cashapp', 'paypal', 'venmo'];

        // check payment details fields
        paymentOptions.forEach(field => {
            const tag = 'ref_' + field;
            if (_this.formKeys[tag].props.errorMessages.length) {
                pass = false;
            }
        });

        if (pass) {
            _this.setWizardStep(step);
        } else {
            _this.setError('Please correct errors.');
            _this.formsyhoc.formsyForm.current.submit();
            _this.scrollToTop();
        }
    }

    checkDetails(step = 4) {
        const _this = this;
        let pass = true;

        // check text details fields
        Object.keys(_this.formKeys).forEach(field => {
            if (field.indexOf('ref_text') === 0 && _this.formKeys[field].props.errorMessages.length) {
                pass = false;
            }
        });

        if (pass) {
            _this.setWizardStep(step);
        } else {
            _this.setError('Please correct errors.');
            _this.formsyhoc.formsyForm.current.submit();
            _this.scrollToTop();
        }
    }

    checkFields(fields = [], step = 2) {
        const _this = this;
        let pass = true;

        fields.forEach(field => {
            const tag = 'ref_' + field;
            // check form field
            if (!_this.formKeys[tag] || _this.formKeys[tag].props.errorMessages.length) {
                pass = false;
            }
        })

        if (pass) {
            _this.setWizardStep(step);
        } else {
            _this.setError('Please correct errors.');
            _this.formsyhoc.formsyForm.current.submit()
            _this.scrollToTop();
        }
    }

    componentRefs(ref, field) {
        const _this = this;
        _this.formKeys['ref_' + field] = ref;
    }

    setWizardInstance(instance) {
        const _this = this;
        _this.wizardInstance = instance;
    }

    setWizardStep(step, uiStep = 0) {
        const _this = this;
        console.log(_this.formsyhoc.refs)
        // _this.refs.form.inputs.forEach((input) => {
        //     if (input.showRequired()) {
        //         console.log("input ", input.props.name, input.props.validationError, typeof input.props.validationError === 'string')
        //         // errs[input.props.name] = typeof input.props.validationError === 'string' ? input.props.validationError : 'Please fill out this field.'
        //     }
        // })
        if (step) {
            _this.wizardInstance.goToStep(step);
            _this.setState({ ..._this.state, apiError: '', confirmedMessage: '', wizardStep: uiStep || step });
            _this.scrollToTop();
            _this.GA.logModal('post-profile/wizard-step/' + step);
        }
    }

    previewProfile() {
        const _this = this;
        const profile = _this.profile;
        const fieldsToGrab = ['paypal', 'venmo', 'cashapp', 'need', 'firstName', 'lastInitial', 'email', 'zipCode'];
        Object.keys(NEED_CATEGORIES).concat(fieldsToGrab).forEach(key => {
            const checkKeys = [key, key.replace(/^need/, 'select'), key.replace(/^need/, 'text')];
            checkKeys.forEach(thisKey => {
                if (_this.formKeys['ref_' + thisKey] && _this.formKeys['ref_' + thisKey].props.value) {
                    let thisValue = _this.formKeys['ref_' + thisKey].props.value
                    if (thisKey === "lastInitial") {
                        thisValue = thisValue[0];
                    }
                    profile[thisKey] = thisValue;
                }
            })

        })

        // specific for preview of location
        if (_this['ref_locationText'] && _this.state.location.lat !== 0 && _this.state.location.lng !== 0) {
            profile['location'] = {
                coordinates: [_this.state.location.lat, _this.state.location.lng]
            }
            profile['locationText'] = _this['ref_locationText'].props.value;
        }
        return profile;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const { locationError, apiError, confirmedMessage, mode, needLength, locationText, showText, wizardStep } = this.state;
        const actualLocationText = typeof locationText === "string" ? locationText : '';
        const profile = this.profile;
        const profileHeaderText = this.profileHeaderText;

        const { GA } = this.props;

        return (
            mode === 'new' ?
                <Form
                    onValidSubmit={this.submit}
                    ref={(node) => { this.formsyhoc = node; }}
                    onInvalidSubmit={this.notifyFormError}
                    noValidate>
                    {profileHeaderText ? profileHeaderText : ''}
                    <ProfileFormStart mode={this.mode} />
                    {wizardStep > 0 ? <div className="seven columns">
                        <Steps step={wizardStep} />
                    </div> : ''}
                    <div className="seven columns">
                        {apiError ? <div className="margin-top">{GA.logEvent({ category: (this.mode === 'new' ? 'New' : 'Manage') + ' Profile', action: 'API Error', label: apiError })}<Alert class='warning no-margin' text={apiError} /></div> : ''}
                        {confirmedMessage ? <div className="margin-top">{GA.logEvent({ category: (this.mode === 'new' ? 'New' : 'Manage') + ' Profile', action: 'Confirmed', label: confirmedMessage })}<Alert class='success no-margin' text={confirmedMessage} /></div> : ''}
                    </div>
                    <div className="seven columns wizard-box">
                        <StepWizard transitions={this.state.transitions} instance={instance => this.setWizardInstance(instance)}>
                            <ProfileFormContact componentRefs={this.componentRefs} isMobile={isMobile} profile={profile} mode={this.mode} nextButton={() => this.checkFields(['firstName', 'lastInitial', 'email'], 2)} onBlurCheckLocation={this.onBlurCheckLocation} onChangeLocation={this.onChangeLocation} actualLocationText={actualLocationText} locationError={locationError} />
                            <ProfileFormCategory componentRefs={this.componentRefs} previousButton={() => this.setWizardStep(1)} nextButton={() => this.checkNeeds(3)} componentRefs={this.componentRefs} isMobile={isMobile} profile={profile} textBox={this.textBox} />
                            <ProfileFormDetail componentRefs={this.componentRefs} previousButton={() => this.setWizardStep(2)} nextButton={() => this.checkDetails(4)} componentRefs={this.componentRefs} isMobile={isMobile} profile={profile} showText={showText} />
                            <ProfileFormFinancial previousButton={() => this.setWizardStep(3, 3)} nextButton={() => this.checkPayment(5)} componentRefs={this.componentRefs} isMobile={isMobile} profile={profile} />
                            <ProfileFormIntro previousButton={() => this.setWizardStep(4, 3)} nextButton={() => this.setWizardStep(6, 4)} componentRefs={this.componentRefs} isMobile={isMobile} profile={profile} checkNeedLength={this.checkNeedLength} />
                            <ProfileFormSubmit mode={mode} previousButton={() => this.setWizardStep(5, 4)} componentRefs={this.componentRefs} isMobile={isMobile} profile={this.previewProfile()} />
                        </StepWizard>
                        <div className="clear"></div>
                        <div className="get-help-with-profile  mouseover" onClick={() => { window.open('/request-assistance?from=wizard', '_gethelp'); }}><span className="bold">Need assistance creating a profile?</span> Please <a href='/request-assistance?from=wizard' className="underline" target='_gethelp'>contact us</a>!</div>
                    </div>

                </Form> :
                <Form
                    onValidSubmit={this.submit}
                    onInvalidSubmit={this.notifyFormError}
                    noValidate>
                    <div>
                        {profileHeaderText ? profileHeaderText : ''}
                        {apiError ? <div>{GA.logEvent({ category: (this.mode === 'new' ? 'New' : 'Manage') + ' Profile', action: 'API Error', label: apiError })}<Alert class='warning' text={apiError} /></div> : ''}
                        {confirmedMessage ? <div>{GA.logEvent({ category: (this.mode === 'new' ? 'New' : 'Manage') + ' Profile', action: 'Confirmed', label: confirmedMessage })}<Alert class='success' text={confirmedMessage} /></div> : ''}
                        <ProfileFormStart mode={this.mode} />
                        <ProfileFormContact componentRefs={this.componentRefs} profile={profile} mode={this.mode} onBlurCheckLocation={this.onBlurCheckLocation} onChangeLocation={this.onChangeLocation} actualLocationText={actualLocationText} locationError={locationError} />
                        <ProfileFormCategory componentRefs={this.componentRefs} profile={profile} textBox={this.textBox} />
                        <ProfileFormDetail componentRefs={this.componentRefs} profile={profile} showText={showText} />
                        <ProfileFormIntro componentRefs={this.componentRefs} profile={profile} needLength={needLength} checkNeedLength={this.checkNeedLength} />
                        <ProfileFormFinancial componentRefs={this.componentRefs} profile={profile} needLength={needLength} checkNeedLength={this.checkNeedLength} />
                        <ProfileFormSubmit formNoValidate={this.formNoValidate} componentRefs={this.componentRefs} mode={mode} />
                    </div>
                </Form >
        );
    }
}


export default ProfileForm;