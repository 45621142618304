import React, { Component } from 'react';

class Error404 extends Component {

  render() {
    return (
      <div className="container" >
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Page Not Found!</h2>
              <div id="bolded-line"></div>
            </div>

          </div>
        </div>
        <div className="container">
          <div className="eight columns">
            <h4>Sorry, the page you requested is invalid.</h4>
            <p><a href='/'>Go to Home Page</a></p>
          </div>
        </div>
      </div>
    );
  }
}
export default Error404;