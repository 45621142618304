import React from 'react';

function ButtonLayer(props) {
  return (
    <div className="tp-caption NotGeneric-Title tp-resizeme rs-parallaxlevel-0"
      id={props.id}
      data-x="['left']" data-hoffset={`[${props.hoffset}]`}
      data-y="['middle']" data-voffset={`[${props.voffset}]`}
      data-fontsize={`[${props.fontsize}]`}
      data-lineheight={`[${props.lineheight}]`}
      data-width={props.width ? `[${props.width}]` : 'none'}
      data-height={props.height ? `[${props.height}]` : 'none'}
      data-whitespace="nowrap"
      data-transform_idle="o:1;"
      data-transform_in="y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;s:1500;e:Power4.easeInOut;"
      data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
      data-mask_in="x:0px;y:0px;"
      data-mask_out="x:inherit;y:inherit;"
      data-start="2000"
      data-responsive_offset="on"
      data-elementdelay="0.05"  
      data-responsive_offset="on"
      data-actions={props.url ? `[{"event": "click", "action": "simplelink", "target": "_self", "url": "${props.url}"}]` : ''}
      style={props.style ? props.style : { 'zIndex': 6, 'color': 'white', 'whiteSpace': 'nowrap' }}>
        {props.text}
    </div>
  )
}

export default ButtonLayer;
