const giverCampaign = {
    heading: 'We all need support',
    heading2: 'now and then.',
    primaryButtonLink: '/view-profiles-start?fromCampaign=#campaignId#&v=#index#',
    primaryButtonText: 'Donate',
    secondaryButtonLink: '',
    secondaryButtonText: '',
    cta: 'Change someone’s world in a big way today.',
    cta2: '',
    image: '/images/ads/woman_with_mask.jpg',
    mobileImage: '/images/stockphotos/faces.jpg',
    howItWorks: false
}

const getHelpWMaskCampaign = {
    heading: 'We all need support',
    heading2: 'now and then.',
    primaryButtonLink: '/request-assistance?fromCampaign=#campaignId#&v=#index#',
    primaryButtonText: 'Request assistance',
    secondaryButtonLink: '',
    secondaryButtonText: '',
    cta: 'Connect with a community',
    cta2: 'looking for opportunities to give',
    image: '/images/ads/woman_with_mask.jpg',
    mobileImage: '/images/stockphotos/faces.jpg'
}

const getHelpWKidsCampaign = {
    heading: 'We all need support',
    heading2: 'now and then.',
    primaryButtonLink: '/request-assistance?fromCampaign=#campaignId#',
    primaryButtonText: 'Request assistance',
    secondaryButtonLink: '',
    secondaryButtonText: '',
    cta: 'Connect with a community looking for opportunities to give.',
    image: '/images/ads/woman_with_kids.jpg',
    mobileImage: '/images/stockphotos/faces.jpg'
}

const getHelpM = {
    heading: 'We all need support',
    heading2: 'now and then.',
    primaryButtonLink: '/request-assistance?fromCampaign=#campaignId#',
    primaryButtonText: 'Request assistance',
    secondaryButtonLink: '',
    secondaryButtonText: '',
    cta: 'Connect with a community looking for opportunities to give.',
    image: '/images/ads/man_frustrated_1.jpg',
    mobileImage: '/images/stockphotos/faces.jpg'
}

const getHelpGoogleAds = {
    heading: 'Do you need assistance making ends meet?',
    heading2: 'Request support.',
    primaryButtonLink: '/request-assistance?fromCampaign=#campaignId#',
    primaryButtonText: 'Request assistance',
    secondaryButtonLink: '',
    secondaryButtonText: '',
    cta: 'Connect with a community looking for opportunities to give.',
    image: '/images/ads/woman_with_kids.jpg',
    mobileImage: '/images/stockphotos/faces.jpg'
}

export default {
    volunteer: [

    ],
    M30S1: [
        {
            ...getHelpGoogleAds,
            heading: "It's ok to ask for support.",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        }
    ],
    M30S2: [
        {
            ...getHelpGoogleAds,
            heading: "Lost your job?",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        },
        {
            ...getHelpGoogleAds,
            heading: "Lost your job?",
            heading2: "Request support.",
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        }
    ],
    M30S3: [
        {
            ...getHelpGoogleAds,
            heading: "Get the support you need.",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        },
        {
            ...getHelpGoogleAds,
            heading: "Get the assistance you need.",
            heading2: 'Request support.',
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        }
    ],
    M30S4: [
        {
            ...getHelpGoogleAds,
            heading: "Can't make ends meet?",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        },
        {
            ...getHelpGoogleAds,
            heading: "Can't make ends meet?",
            heading2: 'Request support.',
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg'
        }
    ],
    F30S1: [
        {
            ...getHelpGoogleAds,
            heading: "Get the support you need.",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/woman_with_kids.jpg'
        }
    ],
    F30H1: [
        {
            ...getHelpGoogleAds,
            heading: "Get the support you need.",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/happy_woman.jpg'
        }
    ],
    F30H2: [
        {
            ...getHelpGoogleAds,
            heading: "Get the support you need.",
            heading2: null,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/happy_woman2.jpg'
        }
    ],
    getHelpGA: [
        {
            ...getHelpGoogleAds
        },
        {
            ...getHelpGoogleAds,
            heading: 'Need support during shelter in place?',
            heading2: 'Just ask!',
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        },
        {
            ...getHelpGoogleAds,
            heading: 'Stuck at home during shelter in place?',
            heading2: "It's ok to ask for help!",
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        }
        ,
        {
            ...getHelpGoogleAds,
            heading: 'Lost your job during shelter in place?',
            heading2: "It's ok to ask for help!",
            cta: 'Share your need(s) with our community:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        }
    ],
    getHelpWMask: [
        {
            ...getHelpWMaskCampaign
        },
        {
            ...getHelpWMaskCampaign,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        },
        {
            ...getHelpWMaskCampaign,
            cta: 'Share your need(s) with our community:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        }
    ],
    getHelpWKids: [
        {
            ...getHelpWKidsCampaign,
        },
        {
            ...getHelpWKidsCampaign,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        },
        {
            ...getHelpWKidsCampaign,
            cta: 'Share your need(s) with our community:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        }
    ],
    getHelpMv1: [
        {
            ...getHelpM
        },
        {
            ...getHelpM,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        },
        {
            ...getHelpM,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
        },
    ],
    getHelpMv2: [
        {
            ...getHelpM,
            image: '/images/ads/man_frustrated_2.jpg',
        },
        {
            ...getHelpM,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg',
        },
        {
            ...getHelpM,
            cta: 'Request assistance with:',
            cta2: 'Food \u00b7 Donations \u00b7 Errands \u00b7 Transportation',
            image: '/images/ads/man_frustrated_2.jpg',
        }
    ],
    giveC1: [
        {
            ...giverCampaign,
            heading: 'Now is the time to make a difference,',
            heading2: 'and take action.',
            cta: 'Change someone’s world in a big way today:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Give Kindness',
            image: '/images/ads/heart.jpg',
        },
        {
            ...giverCampaign,
            heading: 'Assisting someone in their time of need',
            heading2: 'is a small way to make a big difference.',            
            cta: 'Give kindness to someone in need:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Give Kindness',
            image: '/images/ads/lead_with_compassion.jpg',
        },
        {
            ...giverCampaign,
            heading: 'Everyone is experiencing the effects during this unprecedented time.',
            heading2: '',            
            cta: 'Give kindness to someone in need:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Give Kindness',
            image: '/images/ads/lead_with_compassion.jpg',
        },
        {
            ...giverCampaign,
            heading: 'Now is the time to make a difference,',
            heading2: 'and take action.',
            cta: 'Change someone’s world in a big way today:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Donate',
            image: '/images/ads/heart.jpg',
        },
        {
            ...giverCampaign,
            heading: 'Assisting someone in their time of need',
            heading2: 'is a small way to make a big difference.',            
            cta: 'Give kindness to someone in need:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Donate',
            image: '/images/ads/lead_with_compassion.jpg',
        },
        {
            ...giverCampaign,
            heading: 'Everyone is experiencing the effects during this unprecedented time.',
            heading2: '',            
            cta: 'Give kindness to someone in need:',
            cta2: 'Words of Encouragement \u00b7 Donations \u00b7 Food',
            primaryButtonText: 'Donate',
            image: '/images/ads/lead_with_compassion.jpg',
        }
    ]
};
