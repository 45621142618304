import React from 'react'
import { SHOW_CHAT, SHOW_PHONE } from '../../../constants'

function Social(props) {
    return (
        <div>
            {/* <!-- Social Icons --> */}
            <ul className="social-icons">
                {/* <li><a className="twitter" href="#"><i className="icon-twitter"></i></a></li> */}
                {/* <li><a className="dribbble" href="#"><i className="icon-dribbble"></i></a></li> */}
                <li><a className="facebook" href="https://www.facebook.com/Bridge-Of-Kindness-108134330842419"><i className="icon-facebook"></i></a></li>
                <li><a className="instagram" href="https://www.instagram.com/bridgeofkindness/"><i className="icon-instagram"></i></a></li>
                {/* <li><a className="linkedin" href="#"><i className="icon-linkedin"></i></a></li> */}
                {/* <li><a className="pinterest" href="#"><i className="icon-pinterest"></i></a></li> */}
            </ul>

            <div className="clear"></div>

            {/* <!-- Contact Details --> */}
            {props.top ?
                <div id="contact-details" className={props.left ? 'float-left margin-bottom' : 'margin-bottom'}>
                    <ul>
                        <li><i className="fa fa-envelope"></i><a href='m&#97;&#105;lto&#58;&#99;%6Fnt&#97;%63%7&#52;&#64;b&#114;idg%&#54;5%6&#70;fkindness&#46;&#99;&#37;&#54;Fm'>c&#111;ntact&#64;br&#105;dge&#111;fk&#105;&#110;dness&#46;org</a></li>
                        {SHOW_PHONE && <li><i className="fa fa-phone">&nbsp; </i><a href="tel://+18883192221">(888) 319-2221</a> </li>}
                        {SHOW_CHAT && <li><span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}><i className="fa fa-comments"></i></span> <span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}>Chat</span> </li>}

                        {/* <li><i className="fa fa-phone"></i>+48 880 450 120</li> */}
                    </ul>
                </div> :
                <div id="contact-details" className={props.left ? 'float-left margin-bottom' : 'margin-bottom'}>
                    <div className="small-margin-top"><i className="fa fa-envelope"></i><a href='m&#97;&#105;lto&#58;&#99;%6Fnt&#97;%63%7&#52;&#64;b&#114;idg%&#54;5%6&#70;fkindness&#46;&#99;&#37;&#54;Fm'>c&#111;ntact&#64;br&#105;dge&#111;fk&#105;&#110;dness&#46;org</a></div>
                    {SHOW_PHONE && <div className="small-margin-top"><i className="fa fa-phone">&nbsp; </i><a href="tel://+18883192221">(888) 319-2221</a> </div>}
                    {SHOW_CHAT && <div className="small-margin-top"><span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}><i className="fa fa-comments"></i></span> <span className="underline mouseover" onClick={() => { window.$crisp.push(['do', 'chat:open']) }}>Chat</span> </div>}
                </div>
            }




        </div >
    )
}

export default Social;