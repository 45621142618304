import React from 'react';
import TextDetail from '../TextDetail';

function ProfileFormDetail({ profile, showText, nextStep, previousStep, isMobile, previousButton, nextButton, componentRefs }) {
    return (
        <div className=" ">
            <div >
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needDonationFinancial"} detailText={"financial assistance"} placeHolder={"Enter any additional specifics you would like to share regarding the financial need (Reason for financial assistance, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needDonationFoodSupplies"} detailText={"food/groceries"} placeHolder={"Enter any additional specifics regarding the need of food and/or household goods.  (Type of food/groceries, quantity required, location, duration, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needVolunteers"} detailText={"volunteers"} placeHolder={"Enter any specifics regarding the need of volunteer(s).  (Number of volunteers needed, type of volunteer work, duration, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needErrands"} detailText={"errands"} placeHolder={"Enter any additional specifics regarding the need of assistance with errands.  (Type of errand(s), location, duration, dates & times, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needLawnCare"} detailText={"lawn care"} placeHolder={"Enter any specifics you would like to share regarding the need for lawn care (Type of lawn care needed, frequency, location, duration, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needPetDonation"} detailText={"pet donation"} placeHolder={"Enter any specifics regarding the the need of a pet donation.  (Type of pet, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needTransportation"} detailText={"transportation"} placeHolder={"Enter any specifics regarding the need of transportation.  (Pick up and drop off location, frequency, dates & times, etc.)"} />
                <TextDetail componentRefs={componentRefs} showText={showText} profile={profile} tag={"needMentalSupport"} detailText={"emotional support"} placeHolder={"Enter any specifics regarding the need of emotional support.  (Prayers, words of encouragement, etc.)"} />
                {nextButton ? <div className="eight columns"><br />
                    <p className="margin-top"><button type="button" className="button wizard-button-back" onClick={previousButton}>Back</button><button type="button" className="button wizard-button margin-left" onClick={nextButton}>Continue</button></p>
                </div> : ''} </div>
        </div>
    )
}

export default ProfileFormDetail;