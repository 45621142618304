import React from 'react';
import Image from '../slider/Image';
import BoxLayer from '../slider/BoxLayer';
import TextLayer from '../slider/TextLayer';
import ButtonLayer from '../slider/ButtonLayer';

function Slider(props) {
    return (

        <article className="content">

            <div id="rev_slider_4_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="classicslider1"
                style={{ 'margin': '0px auto', 'backgroundColor': 'transparent', 'padding': '0px', 'marginTop': '0px', 'marginBottom': '0px' }}>
                {/* <!-- START REVOLUTION SLIDER 5.0.7 auto mode --> */}
                <div id="rev_slider_4_1" className="rev_slider fullwidthabanner" style={{ 'display': 'none' }} data-version="5.0.7">
                    <ul>
                        {/* <!-- Give --> */}
                        <li data-index="rs-16" data-transition="zoomout" data-slotamount="default" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Intro" data-description="">
                            <Image src="/images/stockphotos/donate_1.jpg" />
                            {/* <!-- LAYERS --> */}
                            <BoxLayer id="slide1-layer-1" hoffset="50" voffset="10" width="550" height="375" />
                            <TextLayer id="slide1-layer-2" fontsize="70" lineheight="70" hoffset="100" voffset="-90" text="Give" />
                            <TextLayer id="slide1-layer-3" fontsize="30" lineheight="70" hoffset="150" voffset="-30" text="to someone in need." />
                            <ButtonLayer id="slide1-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/mission" width="325" height="55" text="Lend a Helping Hand" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#008040', 'zIndex': 10 }} />
                            {/* <ButtonLayer id="slide1-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/view-profile" width="220" height="55" text="View profiles" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#008040', 'zIndex': 10 }} /> */}
                            {/* <!-- LAYER NR. 1 --> */}
                        </li>

                        {/* <!-- Get help  --> */}
                        <li data-index="rs-17" data-transition="zoomin" data-slotamount="7" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <Image src="/images/stockphotos/support_your_community.jpg" />
                            <BoxLayer id="slide2-layer-1" hoffset="50" voffset="10" width="550" height="375" />
                            <TextLayer id="slide2-layer-2" fontsize="70" lineheight="70" hoffset="100" voffset="-90" text="Connect" />
                            <TextLayer id="slide2-layer-3" fontsize="30" lineheight="70" hoffset="150" voffset="-30" text="with your community." />
                            <ButtonLayer id="slide1-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/volunteer" width="290" height="55" text="Volunteer With Us" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#008040', 'zIndex': 10 }} />
                            {/* <ButtonLayer id="slide2-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/post-profile" width="320" height="55" text="Request assistance" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#336699', 'zIndex': 10 }} /> */}
                        </li>

                        {/* <!-- Give  --> */}
                        <li data-index="rs-18" data-transition="zoomin" data-slotamount="7" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <Image src="/images/stockphotos/lead_with_compassion.jpg" />
                            <BoxLayer id="slide3-layer-1" hoffset="50" voffset="10" width="550" height="375" />
                            <TextLayer id="slide3-layer-2" fontsize="70" lineheight="70" hoffset="100" voffset="-90" text="Lead" />
                            <TextLayer id="slide3-layer-3" fontsize="30" lineheight="70" hoffset="150" voffset="-30" text="with compassion." />
                            <ButtonLayer id="slide3-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/about" width="255" height="55" text="Join Our Mission" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#008040', 'zIndex': 10 }} />
                            {/* <ButtonLayer id="slide3-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/view-profile" width="220" height="55" text="View profiles" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#008040', 'zIndex': 10 }} /> */}
                        </li>

                        {/* <!-- Get help  --> */}                            {/* <!-- MAIN IMAGE --> */}
                        {/* <li data-index="rs-19" data-transition="zoomin" data-slotamount="7" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
                            <Image src="/images/stockphotos/woman-with-mask.jpg" />
                            <BoxLayer id="slide4-layer-1" hoffset="50" voffset="10" width="550" height="375" />
                            <TextLayer id="slide4-layer-2" fontsize="70" lineheight="70" hoffset="100" voffset="-90" text="Find" />
                            <TextLayer id="slide4-layer-3" fontsize="30" lineheight="70" hoffset="150" voffset="-30" text="the support you need." />
                            <ButtonLayer id="slide4-layer-4" fontsize="30" lineheight="55" hoffset="100" voffset="110" url="/post-profile" width="320" height="55" text="Request assistance" style={{ 'color': 'white', 'cursor': 'pointer', 'padding': '5px 5px 5px 20px', 'backgroundColor': '#336699', 'zIndex': 10 }} />
                        </li> */}
                    </ul>
                    <div className="tp-static-layers"></div>
                    <div className="tp-bannertimer" style={{ 'height': '7px', 'backgroundColor': 'rgba(255, 255, 255, 0.25)' }}></div>
                </div>
            </div>
            {/* <!-- END REVOLUTION SLIDER --> */}

        </article>
    )
}

export default Slider;
