import React, { Component } from 'react';
import axios from 'axios';
import { isMobile } from '../components/utils/Mobile';

import Social from '../components/social/Social';
import { Form, Input, Textarea, Select } from 'formsy-react-components';
import 'react-responsive-modal/styles.css';

import Alert from '../components/messages/Alert';
class Contact extends Component {

    constructor(props) {
        super(props)
        this.submitMessage = this.submitMessage.bind(this);

        this.GA = props.GA;
        this.ReactPixel = props.ReactPixel;

        this.state = {
            apiError: '',
            messageApiError: ''
        };
    }

    submitMessage(message, resetForm, invalidateForm) {
        const _this = this;

        if (!message.message) {
            _this.setMessageError("Please enter text for your message", true);
            return;
        }

        if (!message.from) {
            _this.setMessageError("Please enter your name", true);
            return;
        }

        if (!message.message) {
            _this.setMessageError("Please enter your email", true);
            return;
        }

        // determine post or patch
        const request = {
            url: '/api/contact',
            method: 'post',
            data: {
                from: message.from,
                subject: message.subject,
                message: message.message,
                email: message.email
            }
        }

        axios(request)
            .then(function (result) {
                _this.setConfirmed(`Message sent!`);
                _this.ReactPixel.track('track', { 'page': 'Contact Form-0', 'action': 'SendMessage' });
                _this.ReactPixel.trackCustom('track', { 'page': 'Contact Form-1', 'action': 'SendMessage' });
                _this.ReactPixel.track('ContactForm-2', { 'action': 'SendMessage' });
                _this.ReactPixel.fbq('track', 'Contact Form-3', { action: 'SendMessage' })
                _this.ReactPixel.fbq('trackCustom', 'Contact Form-4', { action: 'SendMessage' })

                resetForm();
            })
            .catch(function (error) {
                _this.setError('Error submitting message', true);
            }).finally(() => { });
    }


    setConfirmed(message) {
        const _this = this;
        if (message) {
            _this.setState({ ..._this.state, confirmedMessage: message });
            setTimeout(() => {
                _this.setState({ ..._this.state, confirmedMessage: '' });
            }, 5000)
        } else {
            _this.setState({ ..._this.state, confirmedMessage: '' });
        }
    }

    setMessageError(error, fade) {
        const _this = this;
        _this.setState({ ..._this.state, messageApiError: error });
        if (fade) {
            setTimeout(() => {
                _this.setState({ ..._this.state, messageApiError: '' });
            }, 5000)
        }
    }

    setError(error, fade) {
        const _this = this;
        _this.setState({ ..._this.state, apiError: error });
        if (fade) {
            setTimeout(() => {
                _this.setState({ ..._this.state, apiError: '' });
            }, 5000)
        }
    }

    render() {

        const subjectOptions = [
            { value: 'General Information', label: 'General Information' },
            { value: 'Donate to Bridge of Kindness', label: 'Donate to Bridge of Kindness' },
            { value: 'Media Inquiry', label: 'Media Inquiry' },
            { value: 'Website Feedback', label: 'Website Feedback' },
            { value: 'Share Testimonial', label: 'Share Testimonial' },
            { value: 'Report Abuse', label: 'Report Abuse' }
        ];

        const { messageApiError, confirmedMessage } = this.state;
        const { GA } = this.props;

        return (
            <div>
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Contact</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                    <div className="sixteen columns">
                        <p>Contact us using the form provided below.  We look forward to hearing from you!</p>
                    </div>
                    <div className="container">

                        <div className="eleven columns">
                            <div className="headline low-margin"><h4>Contact Form</h4></div>

                            <Form onSubmit={this.submitMessage}>
                                {messageApiError ? <div>{GA.logEvent({ category: 'Contact Form', action: 'API Error', label: messageApiError })}<Alert class='warning' text={messageApiError} /></div> : ''}
                                {confirmedMessage ? <div>{GA.logEvent({ category: 'Contact Form', action: 'Confirmed', label: confirmedMessage })}<Alert class='success' text={confirmedMessage} /></div> : ''}
                                <div>
                                    <Input id="from" className="long-text-field" validations="isAlpha" label="Name" layout="vertical" name="from" placeholder="Name/Organization" required />
                                    <Input id="email" className="long-text-field" validations="isEmail" label="Email" layout="vertical" name="email" placeholder="joe@smith.com" required />
                                    <Select
                                        label="Subject"
                                        layout="vertical"
                                        name="subject"
                                        value="General Information"
                                        options={subjectOptions}
                                    />
                                    <Textarea className="margin-top" id="message" value="" layout="vertical" name="message" cols="50" rows="5" placeholder='Your message ...' />
                                    <br />
                                    <button id="submit" type="submit" className="button color">Send Message</button>

                                </div>

                            </Form>
                        </div>
                        {/* hide for mobile due to redundancy */}
                        {!isMobile ?
                            <div className="five columns">
                                <div className="headline low-margin"><h3>Social</h3></div>
                                <Social left={true}></Social>
                            </div> : <div>&nbsp;<br /><br /><br /></div>}
                    </div>

                </div>


            </div>
        );
    }
}
export default Contact;