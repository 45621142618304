import React from 'react';
import moment from 'moment';

function truncate(input) { return input.length > 5 ? `${input.substring(0, 50)}...` : input; }

function MessageList(props) {
    
    const { messageList, openMessage, filter } = props;
    const messages = messageList.filter(message => message.bucket === filter);

    return (
      <ul className="message-list">
        {messages.length ?
          messages.map((message, i) =>
            (
              <li className="" key={message._id}> {/* new */}
                {/* <input type="checkbox" /> */}
                <div className="preview" onClick={() => openMessage(message._id)}>
                  {message.from ?
                    <div>
                      <h3>{message.from}<small>{moment(message.createdDateTime).format("MMMM Do")}</small></h3>
                      <p>{message.subject ? <span><strong>{message.subject}</strong>&nbsp;&nbsp;{truncate(message.message)}</span> : truncate(message.message)}</p>
                    </div>
                    :
                    <p>{message.subject ? <span><strong>{message.subject}</strong>{truncate(message.message)}</span> : truncate(message.message)}<small className="float-right">{moment(message.createdDateTime).format("MMMM Do")}</small></p>
                  }
                </div>
              </li>
            )
          ) :
          <li className="">
            <div className="preview">
              <p>No messages.</p>
            </div>
          </li>
        }
      </ul>
    )
  }

  export default MessageList;
