import React, { Component } from 'react';
import { isMobile, isTablet } from '../components/utils/Mobile'
import Landing from '../components/home/Landing';
import HomeLanding from '../components/home/HomeLanding';
import CAMPAIGNS from '../../constants/campaigns';

class Campaign extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { campaignId } = this.props.match.params || null;

        const options = CAMPAIGNS[campaignId];

        if (Array.isArray(options) && options.length) {
            const index = Math.floor(Math.random() * options.length);
            const option = options[index];
            return (
                <HomeLanding>
                    <Landing
                        primaryButtonLink={option.primaryButtonLink.replace('#campaignId#', campaignId).replace('#index#', index) + (isMobile ? '&isMobile=1' : '') + (isTablet ? '&isTablet=1' : '')}
                        primaryButtonText={option.primaryButtonText}
                        secondaryButtonLink={option.secondaryButtonLink.replace('#campaignId#', campaignId).replace('#index#', index) + (isMobile ? '&isMobile=1' : '') + (isTablet ? '&isTablet=1' : '')}
                        secondaryButtonText={option.secondaryButtonText}
                        heading={option.heading}
                        heading2={option.heading2}
                        cta={option.cta}
                        cta2={option.cta2}
                        image={option.image}
                        mobileImage={option.mobileImage}
                        campaignId={campaignId}
                        howItWorks={options.howItWorks}
                    />
                </HomeLanding>
            );
        } else {
            this.props.history.push(`/`);
            return null;
        }
    }
}

export default Campaign
