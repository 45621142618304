import React from 'react'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
import { REACT_APP_FACEBOOK_APP_ID, MISSION_TEXT } from '../../../constants';

function Social(props) {
    const size = props.size || 40;
    const url = props.url || "https://www.bridgeofkindness.org";
    const shortText = props.shortText || "Bridge of Kindness";
    const text = props.text || MISSION_TEXT
    return (
        <div className="small-margin-top">
            <h4>
                <FacebookShareButton url={url} quote={text} className="share-icon">
                    <FacebookIcon size={size} round={true} />
                </FacebookShareButton>
                {/* <FacebookMessengerShareButton appId={REACT_APP_FACEBOOK_APP_ID} url={url} quote={text} className="share-icon">
                    <FacebookMessengerIcon size={size} round={true} />
                </FacebookMessengerShareButton> */}
                <LinkedinShareButton url={url} summary={text} title={shortText} className="share-icon">
                    <LinkedinIcon size={size} round={true} />
                </LinkedinShareButton>
                <RedditShareButton url={url} title={shortText} className="share-icon">
                    <RedditIcon size={size} round={true} />
                </RedditShareButton>
                <TwitterShareButton url={url} title={shortText} string={text} className="share-icon">
                    <TwitterIcon size={size} round={true} />
                </TwitterShareButton>
                <EmailShareButton url={url} subject={shortText} separator='------------' body={text} className="share-icon">
                    <EmailIcon size={size} round={true} />
                </EmailShareButton>
            </h4>
        </div >
    )
}

export default Social;