import React, { Component } from "react";
import { isMobile, isTablet } from "../utils/Mobile";
import SpreadTheWord from "../social/SpreadTheWord";
import { QUOTES } from "../../../constants";

import "../../pages/css/home.css";

function HomeLanding(props) {
  return (
    <div>
      <div className="container">
        <div>
          <div className="sixteen-columns">{props.children}</div>
          <div className="sixteen columns" id="home-body">
            <div className="headline show-if-small">&nbsp;</div>
          </div>
          <div
            className={isMobile ? "five columns" : "five columns margin-top"}
          >
            <div className="home-text-padding">
              <p className="home-page-text">
                <span className="home-page-text-bold">Bridge of Kindness</span>{" "}
                was established due to the current, ever-growing need, for
                embodying kindness, helping those in need, and creating a
                strong, unified community.
              </p>
              <br />

              <p className="home-page-text">
                We aim to connect people to maximize resources and minimize
                everyday struggles and despair.
              </p>
              <br />
              <p className="home-page-text">
                Our mission is to show compassion to those that require it most;
                our neighbors, our community, and those in need.{" "}
              </p>
            </div>
          </div>
          <div
            className={
              isMobile ? "six columns margin-bottom" : "six columns margin-top"
            }
          >
            <div className="home-image-padding">
              {/* <a href='/view-profile'><img className="tree" src="/images/stockvectors/tree.png" /></a> */}
              <a href="/view-profile">
                <img
                  style={{ margin: "0px auto", width: "300px" }}
                  src="/images/stockvectors/heart.png"
                />
              </a>
              <div className="learn-more italic">
                <a href="/about?fromHomeLanding" className="underline">
                  Learn more
                </a>{" "}
                about the origins of our mission.
                <br />
                <p className="small-margin-top">Spread the word!</p>
                <SpreadTheWord size={32} />
              </div>
            </div>
          </div>
          <div
            className={isMobile ? "five columns" : "five columns margin-top"}
          >
            <div
              className={
                isMobile
                  ? isTablet
                    ? "home-text-padding"
                    : "home-text-padding large-margin-top"
                  : "home-text-padding"
              }
            >
              <h4>What makes us different...</h4>
              <p className="home-page-text">
                Donations can be made to numerous organizations in need, but not
                all donations are alike.
              </p>
              <br />

              <p className="home-page-text">
                Bridge of Kindness not only offers the opportunity to make a
                donation, but also encourages neighborly acts of kindness that
                help people get through the current crisis.
              </p>
              <br />
              <h4>
                <a className="underline" href="/view-profile">
                  Get involved
                </a>{" "}
                today!
                <br />
              </h4>
            </div>
          </div>
          <div className="clear"></div>
          {/* <div className="mobile-action-box margin-top show-if-small">
            <div className="eight columns">
              <div className="mobile-action-content-small show-if-small  margin-bottom">
                <div className="mobile-action-content-top">
                  <h4>Need assistance?</h4>
                  <p className="nudge-margin-top">
                    Share your need with our community.
                  </p>
                </div>
                <div className="mobile-action-content-bottom">
                  <a
                    className="underline"
                    href="/post-profile?from=home-mobile"
                  >
                    {" "}
                    <button className="button color">Request assistance</button>
                  </a>
                </div>
                <div className="clear"></div>
              </div>
              <div className="mobile-action-content show-if-large">
                <div className="mobile-action-content-top">
                  <h4>Need assistance?</h4>
                  <p>Share your need with our community.</p>
                </div>
                <div className="mobile-action-content-bottom">
                  <a
                    className="underline"
                    href="/post-profile?from=home-mobile"
                  >
                    {" "}
                    <button className="button color float-right">
                      Request assistance
                    </button>
                  </a>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            <div className="eight columns">
              <div className="mobile-action-content-small show-if-small">
                <div className="mobile-action-content-top">
                  <h4>Want to assist?</h4>
                  <p className="nudge-margin-top">
                    View profiles and find ways to give.
                  </p>
                </div>
                <div className="mobile-action-content-bottom">
                  <a
                    className="underline"
                    href="/view-profile?from=home-mobile"
                  >
                    {" "}
                    <button className="button color">View profiles</button>
                  </a>
                </div>
                <div className="clear"></div>
              </div>
              <div className="mobile-action-content  show-if-large">
                <div className="mobile-action-content-left">
                  <h4>Want to assist?</h4>
                  <p>View profiles and find ways to give.</p>
                </div>
                <div className="mobile-action-content-right">
                  <a
                    className="underline"
                    href="/view-profile?from=home-mobile"
                  >
                    {" "}
                    <button className="button color float-right">
                      View profiles
                    </button>
                  </a>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div> */}
          {/* <div className="headline show-if-large">&nbsp;</div> */}
          {/* <div className="max-home-width image-bar  show-if-large">
            <div
              className="eleven columns home-image"
              style={{
                backgroundImage: "url(/images/ads/woman_with_kids.jpg)",
              }}
            >
              &nbsp;
            </div>
            <div className="five columns home-image-highlights">
              <div className="home-image-highlights-inner right-align">
                <h3>
                  <span className="underline">Everyone</span> needs support from
                  time to time.
                </h3>
                <br />
                <h3>
                  Request assistance from a community seeking to give to persons
                  in need.
                </h3>
                <br />
                <button
                  type="button"
                  onClick={() => {
                    window.location.href = "/post-profile?from-home";
                  }}
                  className="home-image-button margin-top button color small"
                >
                  Request assistance
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <br />
      <br />
      <div
        id="testimonials"
        style={{ backgroundImage: "url(/images/stockphotos/faces.jpg)" }}
      >
        <div className="container">
          <div className="sixteen columns">
            <div className="testimonials-slider">
              <ul className="slides">
                {QUOTES.map((q, i) => (
                  <li key={i}>
                    <p>
                      {q.q}
                      <span>{q.a}</span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className="container show-if-large">
                <div className="max-home-width image-bar">
                    <div className="five columns home-image-highlights">
                        <div className="home-image-highlights-inner">
                            <h3><span className="underline">You</span> can make a difference.</h3>
                            <br />
                            <h3>View profiles of people in need and find opportunities to give.</h3>
                            <br />
                            <button type="button" onClick={() => { window.location.href = '/view-profile?from-home' }} className="home-image-button margin-top button color small">View profiles</button>
                        </div>
                    </div>
                    <div className="eleven columns home-image" style={{ backgroundImage: 'url(/images/ads/help_with_groceries_shutin.jpg)' }}  >
                        &nbsp;
              </div>
                </div>
            </div> */}
    </div>
  );
}

export default HomeLanding;
