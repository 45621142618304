import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { MAPS_API_KEY } from '../../../constants';
import { isMobile, isTablet } from '../../components/utils/Mobile';

class MapComponent extends Component {
    render() {
        const { center, zoom } = this.props;
        const style = {
            marginTop: '7px',
            width: isTablet ? '115px' : '160px',
            height: isTablet ? '115px' : '160px',
            border: '1px solid grey'
        }

        return (
            <div className="Map show-if-large">
                <Map key={Math.random} google={this.props.google} initialCenter={center} center={center} zoom={zoom} disableDefaultUI={true} style={style}>
                    <Marker position={center} />
                </Map>
            </div>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (MAPS_API_KEY)
})(MapComponent);