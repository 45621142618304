import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'react-cookies'
import { isMobile } from '../components/utils/Mobile';

import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Form } from 'formsy-react-components';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ClipLoader from "react-spinners/MoonLoader";

import Profile from '../components/profile/ProfileForm/ProfileForm';
import MessageList from '../components/profile/MessageList';

import GA from '../components/utils/GoogleAnalytics'

import './css/messages.css'

let messageData = {}, messageModalTimer;

class ManageProfile extends Component {
  constructor(props) {
    super(props);
    this.openMessage = this.openMessage.bind(this);
    this.setMessageBucket = this.setMessageBucket.bind(this);
    this.redirectError = this.redirectError.bind(this);
    this.setShowProfileStatusModal = this.setShowProfileStatusModal.bind(this);
    this.setShowProfileRemoveModal = this.setShowProfileRemoveModal.bind(this);
    this.setShowProfileRemoveProblemModal = this.setShowProfileRemoveProblemModal.bind(this);
    this.setShowMessageModal = this.setShowMessageModal.bind(this);
    this.setShowTrashModal = this.setShowTrashModal.bind(this);
    this.redirect = this.redirect.bind(this);
    this.checkRemoveConfirm = this.checkRemoveConfirm.bind(this);
    this.removeProfile = this.removeProfile.bind(this);
    this.openViewProfile = this.openViewProfile.bind(this)
    this.logout = this.logout.bind(this);
    this.messageCount = this.messageCount.bind(this);

    this.GA = props.GA;

    this.state = {
      open: false,
      redirect: null,
      confirmed: false,
      accessKey: null,
      active: null,
      showProfileStatusModal: false,
      showProfileRemoveSuccessModal: false,
      showProfileRemoveProblemModal: false,
      showMessageModal: false,
      showTrashModal: false,
      hideRemoveProfileButton: true,
      messages: [],
      message: null,
      messagesLoaded: false
    }
  }


  checkRemoveConfirm(input) {
    const _this = this;
    _this.setState({ ..._this.state, hideRemoveProfileButton: input.target.value === 'delete' ? false : true });
  }

  setProfileStatus(status) {
    const _this = this;
    axios.get(`/api/profile/${_this.state.accessKey}` + (status === true ? '/activate' : '/deactivate'))
      .then(function (response) {
        const profile = _this.state.profile;
        profile.active = status;

        GA.logEvent({ category: 'Manage Profile', action: status ? 'Enable Profile' : 'Disable Profile' });

        if (response.data) {
          _this.setState({ ..._this.state, profile, showProfileStatusModal: false });
          document.getElementById('focusHack').focus();
        } else {
          _this.redirectError('mp-e2');
        }
      })
      .catch(function (error) {
        _this.redirectError('mp-e3');
      })
  }

  removeProfile() {
    const _this = this;

    axios.delete(`/api/profile/${_this.state.accessKey}`)
      .then(function (response) {
        if (response.data) {
          _this.setState({ ..._this.state, showProfileRemoveSuccessModal: true, showProfileRemoveModal: false });
          setTimeout(function () {
            _this.redirect('/get-profile/mp-login');
          }, 3000)
        } else {
          _this.setShowProfileRemoveModal(false);
          setTimeout(() => {
            _this.setShowProfileRemoveProblemModal(true);
          }, 250)
        }
      })
      .catch(function (error) {
        _this.setShowProfileRemoveModal(false);
        setTimeout(() => {
          _this.setShowProfileRemoveProblemModal(true);
        }, 250)
      })
  }

  emptyTrash() {
    const _this = this;
    axios.get(`/api/delete-trash-messages/${_this.state.profile.viewAccessKey}`)
      .then(function (response) {
        _this.setShowTrashModal(false);
        _this.getMessages();
      })
      .catch(function (error) {
        _this.setShowTrashModal(false);
      })
  }

  setShowProfileRemoveModal(showModal) {
    const _this = this;
    _this.setState({ ..._this.state, showProfileRemoveModal: showModal });
    document.getElementById('tab1').focus();
    if (showModal) {
      GA.logModal('manage-profile/remove-profile');
    }
  }

  setShowMessageModal(showModal) {
    const _this = this;
    const newState = { ..._this.state, showMessageModal: showModal }

    if (showModal && messageModalTimer) {
      clearTimeout(messageModalTimer);
    }

    if (showModal) {
      GA.logModal('manage-profile/view-message');
    }

    if (!showModal) {
      messageModalTimer = setTimeout(() => {
        const newState = { ..._this.state }
        newState.message = null;
        _this.setState(newState);
      }, 1000)
    }
    _this.setState(newState);
  }

  setShowProfileRemoveProblemModal(showModal) {
    const _this = this;
    _this.setState({ ..._this.state, showProfileRemoveProblemModal: showModal });
    if (showModal) {
      GA.logModal('manage-profile/remove-profile-error');
    }
  }

  setShowProfileStatusModal(showModal, el = null) {
    const _this = this;
    _this.setState({ ..._this.state, showProfileStatusModal: showModal });
    if (showModal) {
      GA.logModal('manage-profile/change-profile-status');
    }
  }

  setShowTrashModal(showModal, el = null) {
    const _this = this;
    _this.setState({ ..._this.state, showTrashModal: showModal });
    if (showModal) {
      GA.logModal('manage-profile/empty-message-trash');
    }
  }

  redirect(url = '/') {
    const _this = this;
    _this.setState({ ..._this.state, redirect: url });
  }

  redirectError(error = 'e1') {
    const _this = this;
    _this.setState({ ..._this.state, redirect: `/get-profile/${error}` });
  }

  openViewProfile() {
    const _this = this;
    const url = '/view-profile/' + _this.state.profile.viewAccessKey
    window.open(url, "_blank");
  }

  loadProfile(props) {
    const _this = this;
    let { confirmed, accessKey } = props.match.params;

    // load cookie if found
    if (!accessKey && cookie.load('accessKey')) {
      accessKey = cookie.load('accessKey');
    }

    // if still no access key, then redirect
    if (!accessKey) {
      setTimeout(() => {
        _this.redirectError('mp-login')
      }, 500)
      return;
    }

    const expires = new Date()
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 1)

    axios.get(`/api/profile/${accessKey}`)
      .then(function (response) {
        if (response.data) {
          cookie.save('accessKey', accessKey, { expires, path: '/' })
          _this.setState({ ..._this.state, confirmed, accessKey, profile: response.data });
          _this.getMessages()
        } else {
          _this.redirectError('mp-e2');
        }
      })
      .catch(function (error) {
        _this.redirectError('mp-e3');
      });
  }

  setMessageBucket({ id, bucket }) {
    const _this = this;
    const request = {
      url: '/api/message/' + id,
      method: 'patch',
      data: {
        viewAccessKey: _this.state.profile.viewAccessKey,
        bucket,
      }
    }

    axios(request)
      .then(function (result) {
        _this.getMessages();
        _this.setShowMessageModal(false);
      })
      .catch(function (error) {
        // console.log(error)
      }).finally(() => { });
  }

  getMessages() {
    const _this = this;
    axios.get(`/api/message/${_this.state.profile.viewAccessKey}`)
      .then(function (response) {
        const messages = response.data.map(data => {
          const { _id, message, from, subject, bucket, createdDateTime } = data;
          return { _id, key: _id, message, from, subject, bucket, createdDateTime }
        })
        _this.setState({ ..._this.state, messages, messagesLoaded: true });
        window['setupTabs']()
      })
      .catch(function (error) {
        _this.redirectError('mp-e6');
      });
  }

  openMessage(id) {
    const _this = this;
    setTimeout(() => {
      _this.setState({ ..._this.state, message: messageData[id] });
      _this.setShowMessageModal(true)
    }, 100)
  }

  messageCount(bucket) {
    const _this = this;
    return _this.state.messages.filter(message => message.bucket === bucket).length;
  }

  componentDidMount() {
    const _this = this;
    // load profile after components is mounted
    _this.setState({ ..._this.state, profileReady: true });
    this.loadProfile(this.props)
  }

  componentWillUnmount() {
    const _this = this;
    // something is off when we update in the child and then navigate away we cause an error
    // happens only in 'preview
  }

  logout() {
    const _this = this;
    cookie.remove('accessKey', { path: '/' })
    _this.redirectError('mp-logout');
  }

  render(props) {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { profileReady, messagesLoaded, message, profile, messages, confirmed, accessKey, hideRemoveProfileButton, showTrashModal, showMessageModal, showProfileStatusModal, showProfileRemoveModal, showProfileRemoveSuccessModal, showProfileRemoveProblemModal } = this.state;
    const { GA } = this.props;

    if (!profile || !profile.email) {
      return (
        <div>
        </div>
      )
    }

    const list = messages;
    messageData = {}
    list.forEach(message => {
      messageData[message._id] = message;
    })

    return (
      <div>
        <div className="container">
          <div className="eight columns">
            <div className="headline no-margin"><h3>My Profile</h3></div>
            <p></p>
            <Form>
              <div className={'notification ' + (profile.active ? ' notice ' : ' warning ') + 'preview-bar-height'}>
                <h5 className="float-left">Profile is {profile.active ? <span className='green'>enabled</span> : <span className='grey'>disabled</span>}</h5>
                <div className="float-right">
                  {profile.active ? <button alt="Preview Profile" type="button" className="button margin-left small-button light" onClick={() => this.redirect(`/view-profile/${profile.viewAccessKey}/self`)}><i className="fa fa-search"></i> {isMobile ? '' : 'Preview'}</button> : ''}
                  <button alt="Logout of Profile" type="button" className="button margin-left small-button light" onClick={() => this.logout()}><i className="fa fa-sign-out"></i> {isMobile ? '' : 'Logout'}</button>
                  {profile.active ? <button data-tooltip-no="Disable Profile" type="button" className="margin-left tooltip-bottom button small-button light" onClick={() => this.setShowProfileStatusModal(true)}><i className="fa fa-eye-slash"></i> {isMobile ? '' : 'Disable'}</button> : <button data-tooltip-no="Enable Profile" className="margin-left tooltip-bottom button small-button light" onClick={() => this.setShowProfileStatusModal(true)}><i className="fa fa-eye"></i> Enable</button>}
                  <button className="button margin-left small-button light tooltip-bottom  notice-button-adjust" type="button" data-tooltip-disabled="Remove Profile" onClick={() => this.setShowProfileRemoveModal(true)}><i className="fa fa-trash"></i> {isMobile ? '' : 'Remove'}</button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
              </div>
            </Form>
            {profileReady ?
              <Profile GA={GA} profile={profile} setAccessKey={accessKey} confirmed={confirmed} redirectError={this.redirectError} profileHeaderText={<p></p>} /> :
              <div className="spinner-load-profile">
                <ClipLoader
                  size={50}
                  color={"#495D7F"}
                  loading={true}
                /></div>
            }
          </div>

          <div className="one column">&nbsp;</div>
          <div className="seven columns">
            {messagesLoaded ?
              <div>
                <div className="headline no-margin"><h3>My Messages</h3></div>
                <ul className="tabs-nav margin-top">
                  <li className="active"><a href="#tab1"><i className="fa fa-envelope"></i> {isMobile ? '' : 'Inbox'} {this.messageCount('i') ? '(' + this.messageCount('i') + ')' : ''}</a></li>
                  <li><a href="#tab2"><i className="fa fa-star"></i> {isMobile ? '' : 'Favorites'} {this.messageCount('f') ? '(' + this.messageCount('f') + ')' : ''}</a></li>
                  <li><a href="#tab3"><i className="fa fa-file"></i> {isMobile ? '' : 'Archive'} {this.messageCount('a') ? '(' + this.messageCount('a') + ')' : ''}</a></li>
                  <li><a href="#tab4"><i className="fa fa-trash"></i> {isMobile ? '' : 'Trash'} {this.messageCount('t') ? '(' + this.messageCount('t') + ')' : ''}</a></li>
                </ul>
                <div className="tabs-container">
                  <div className="tab-content" id="tab1" >
                    <div className="messages">
                      <MessageList openMessage={this.openMessage} messageList={messages} filter="i"></MessageList>
                    </div>
                  </div>
                  <div className="tab-content" id="tab2" >
                    <div className="messages">
                      <MessageList openMessage={this.openMessage} messageList={messages} filter="f"></MessageList>
                    </div>
                  </div>
                  <div className="tab-content" id="tab3" >
                    <div className="messages">
                      <MessageList openMessage={this.openMessage} messageList={messages} filter="a"></MessageList>
                    </div>
                  </div>
                  <div className="tab-content" id="tab4" >
                    {this.messageCount('t') ? <button type="button" onClick={() => this.setShowTrashModal(true)} className="button light block margin-bottom">Empty Trash</button> : ""}
                    <div className="messages">
                      <MessageList openMessage={this.openMessage} messageList={messages} filter="t"></MessageList>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
            <p></p>
          </div>
          <input type="text" style={{ "marginLeft": "10000px", "float": "left" }} id="focusHack"></input>
        </div>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showTrashModal}
          onClose={() => this.setShowTrashModal(false)}
        >
          <h2>Empty Trash</h2>
          <div>
            <br />
            <p>Are you sure you want to empty all messages in the trash folder?</p>
            <p>This action cannot be reversed.</p>
          </div>
          <div className="float-right">
            <button type="button" className="button light" onClick={(el) => this.setShowTrashModal(false, el)}>Cancel</button>
            <button type="button" className="margin-left button color" onClick={() => this.emptyTrash(false)}>Empty trash</button>
          </div>
        </Modal>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showProfileStatusModal}
          onClose={() => this.setShowProfileStatusModal(false)}
        >
          <h2>Change Profile Status</h2>
          <br />
          {profile.active ?
            <div>
              <p>Are you sure you want to <b className="bold color">disable</b> your profile?</p>
              <p>This action will prevent this profile from being viewed by the public.</p>
              {GA.logModal('manage-profile/change-profile-status-confirm-disable')}
            </div> :
            <div><p>Are you sure you want to <b className="text-success">enable</b> your profile?</p>
              <p>This action will allow this profile to be viewed by the public.</p>
              {GA.logModal('manage-profile/change-profile-status-confirm-enable')}
            </div>
          }
          <div className="float-right">
            <button className="button light" onClick={(el) => this.setShowProfileStatusModal(false, el)}>Cancel</button>
            {profile.active ?
              <button type="button" className="margin-left button color" onClick={() => this.setProfileStatus(false)}>Disable profile</button>
              :
              <button type="button" className="margin-left button color" onClick={() => this.setProfileStatus(true)}>Enable profile</button>
            }
          </div>
        </Modal>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showProfileRemoveModal}
          onClose={() => this.setShowProfileRemoveModal(false)}>
          <h2>Remove Profile</h2>
          <div>
            <br />
            <p>Are you sure you want to <b className="text-danger">remove</b> your profile?</p>
            <p>This action will <span className="text-danger">delete</span> this profile and all related data.</p>
            <p className="text-danger">This process cannot be undone.</p>
            <p>Type <b>delete</b> in the space below to enable the remove button.</p>
            <Form>
              <input name="checkForMatch" autoComplete="off" onChange={this.checkRemoveConfirm} className="form-control  long-text-field" type="text" placeholder="Type: delete" />
            </Form>
          </div>
          <div className="float-right margin-top">
            {hideRemoveProfileButton ? '' : <button type="button" className="button color" onClick={() => this.removeProfile(false)}>Remove profile</button>}
            <button type="button" className="margin-left button light" onClick={() => this.setShowProfileRemoveModal(false)}>Cancel</button>
          </div>
        </Modal>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showProfileRemoveSuccessModal}
          onClose={() => this.setShowProfileRemoveSuccessModal(false)}>
          <h2>Remove Profile Success</h2>
          <div>
            <br />
            <p>This profile has successfully been removed.</p>
          </div>
        </Modal>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showProfileRemoveProblemModal}
          onClose={() => this.setShowProfileRemoveProblemModal(false)}>
          <h2>Remove Profile Error</h2>
          <div>
            <br />
            <p>An error occured during the removal of your profile.</p>
            <p>Please <a href='/contact'>contact us</a> to request removal of your profile.</p>
          </div>
          <div className="float-right">
            <button type="button" className="button light" onClick={() => {
              this.setShowProfileRemoveModal(false);
              this.redirect('/');
            }}>Done</button>
          </div>
        </Modal>
        <Modal
          center={true}
          showCloseIcon={true}
          open={showMessageModal}
          onClose={() => this.setShowMessageModal(false)}>
          {message ?
            <div className="message-modal">
              <div className="two columns">
                <h6><small>Received:</small></h6>
              </div>
              <div className="three columns">
                <h6>{moment(message.createdDateTime).format('MMMM Do YYYY, h:mm:ss a')} PST</h6>
              </div>
              <div className="clear"></div>
              {message.from ? <div>
                <div className="two columns">
                  <h6><small>From:</small></h6>
                </div>
                <div className="three columns">
                  <h4>{message.from}</h4>
                </div>
              </div>
                : ''}
              <div>

                <div className="headline no-margin">&nbsp;</div>
                <div className="large-notice light-padding">
                  <p>{message.message.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br /></span>
                  })}</p>
                </div>
                <br /><br />
              </div>
              <div className="float-left move-to-text">
                <h5>Move to...</h5>
              </div>

              {message.bucket !== 't' ?
                <div className="margin-left float-right">
                  <button type="button" className="button light" onClick={() => {
                    this.setMessageBucket({ id: message._id, bucket: 't' });
                  }}><i className="fa fa-trash"></i> Trash</button>
                </div>
                : ''}
              {message.bucket !== 'a' ?
                <div className="margin-left float-right">
                  <button type="button" className="button light" onClick={() => {
                    this.setMessageBucket({ id: message._id, bucket: 'a' });
                  }}><i className="fa fa-file"></i> Archive</button>
                </div>
                : ''}
              {message.bucket !== 'f' ?
                <div className="margin-left float-right">
                  <button type="button" className="button light" onClick={() => {
                    this.setMessageBucket({ id: message._id, bucket: 'f' });
                  }}><i className="fa fa-star color"></i> Favorites</button>
                </div>
                : ''}
              {message.bucket !== 'i' ?
                <div className="margin-left float-right">
                  <button type="button" className="button light" onClick={() => {
                    this.setMessageBucket({ id: message._id, bucket: 'i' });
                  }}><i className="fa fa-envelope"></i> Inbox</button>
                </div>
                : ''}
            </div>
            : ''}
        </Modal>
      </div>
    );
  }
}

export default ManageProfile;
