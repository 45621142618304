import React from 'react';
import { Textarea, Select } from 'formsy-react-components';
import { MAX_TEXTAREA_SIZE, NEED_OPTIONS } from '../../../constants';

const options = NEED_OPTIONS;

function TextDetail({ showText, detailText, tag, placeHolder, profile, componentRefs }) {
    const status = showText[tag]
    const textTag = 'text' + tag.replace(/^need/, '');
    const selectTag = 'select' + tag.replace(/^need/, '');

    if (status) {
        return (
            <div className="eight columns">
                {options[tag] ?
                    <div>
                        <label className="col-form-label">Reason for assistance <span className="bold">{detailText}</span>:</label>
                        <Select className="margin-bottom" id={`select_${tag}`}
                            name={selectTag}
                            value={profile[selectTag] || options[tag][0]['value']}
                            options={options[tag]}
                            componentRef={(node) => { componentRefs(node, selectTag) }}
                        /></div> : ''}
                <Textarea
                    id={`text_${tag}`}
                    value={profile[textTag]}
                    label={`Additional details for ${detailText}`}
                    name={textTag} placeholder={placeHolder}
                    validations={`maxLength:${MAX_TEXTAREA_SIZE}`}
                    validationError={`Details for ${detailText} should be provided and be ${MAX_TEXTAREA_SIZE} characters or less`}
                    maxLength={MAX_TEXTAREA_SIZE}
                    rows="4"
                    required
                    componentRef={(node) => { componentRefs(node, textTag) }}
                ></Textarea>
            </div >)
    } else {
        return (<div></div>);
    }
}

export default TextDetail;