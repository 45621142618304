import React, { Component } from 'react';
import axios from 'axios';
import { Form, Input, Checkbox } from 'formsy-react-components';
import Alert from '../components/messages/Alert';
import scrambleAutoComplete from '../components/utils/Scramble'
import { MAX_NAME_LENGTH, NEW_PROFILE_ALERTS } from '../../constants'

import '../pages/css/newsletter.css'

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.setError = this.setError.bind(this);
    this.setConfirmed = this.setConfirmed.bind(this);
    this.notifyFormError = this.notifyFormError.bind(this);
    this.submit = this.submit.bind(this);

    this.GA = props.GA;

    this.state = {
      confirmedMessage: '',
      apiError: '',
      redirect: null,
      email: '',
      newsletter: {}
    };

  }

  setError(error, fade) {
    const _this = this;
    _this.setState({ ..._this.state, apiError: error });
    if (fade) {
      setTimeout(() => {
        _this.setState({ ..._this.state, apiError: '' });
      }, 5000)
    }
  }

  setConfirmed(message) {
    const _this = this;
    if (message) {
      _this.setState({ ..._this.state, confirmedMessage: message });
      _this.timer = setTimeout(() => {
        _this.setState({ ..._this.state, confirmedMessage: '' });
      }, 5000)
    } else {
      _this.setState({ ..._this.state, confirmedMessage: '' });
    }
  }

  notifyFormError(data, resetForm, invalidateForm) {
    const _this = this;
    let errs = {}
    invalidateForm(errs)
  }

  submit(profile, resetForm) {
    const _this = this;
    if (!profile.email) {
      _this.setError("Please enter your email address.", true);
      return;
    }

    const saveObject = {
      email: profile.email.toLowerCase()
    }

    const optionalKeys = ['dailyNewProfileAlert', 'weeklyNewProfileAlert', 'newsAndAnnouncements', 'profileWaitingList', 'firstName', 'lastName',]

    const newSaveObject = optionalKeys.reduce((thisObject, key) => {
      if (profile[key]) {
        thisObject[key] = profile[key];
      }
      return thisObject;
    }, saveObject);

    const request = {
      url: '/api/newsletter-sign-up',
      method: 'post',
      data: newSaveObject
    }

    axios(request)
      .then(function (response) {
        _this.setConfirmed(response.data.response.message || 'Newsletter/alert preferences updated!');
        resetForm()
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.duplicateError) {
          _this.setError('There was an error processing your subscription request.  Email already subscribed.', true)
        } else {
          _this.setError('There was an error processing your subscription request.', true)
        }
      }).finally(() => {

      });
  }

  render() {
    const { apiError, confirmedMessage, newsletter } = this.state;
    const GA = this.GA;

    return (
      <div className="container">
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Newsletters and alerts</h2>
              <div id="bolded-line"></div>
            </div>
          </div>
          <div className="sixteen columns">
            <p>Want to know more about the work of Bridge of Kindness? Want to be alerted when a person in need posts a new profile? Sign up for our newsletter and email alert system.</p>
          </div>
          <Form
            onValidSubmit={this.submit}
            onInvalidSubmit={this.notifyFormError}
            noValidate
          >
            <div className="ten columns">
              {apiError ? <div>{GA.logEvent({ category: 'Newsletter', action: 'Subscribe Error', label: apiError })}<Alert class='warning' text={apiError} /></div> : ''}
              {confirmedMessage ? <div>{GA.logEvent({ category: 'Newsletter', action: 'Subscribe Error', label: confirmedMessage })}<Alert class='success' text={confirmedMessage} /></div> : ''}
            </div>
            <div className="clear"></div>
            <div className="five columns">
              <Input onFocus={el => scrambleAutoComplete(el)} label="First name" className="medium-text-field" validationError="Please enter a first name" type="text" validations="isAlpha" maxLength={MAX_NAME_LENGTH} name="firstName" placeholder="Jane" />
            </div>
            <div className="five columns">
              <Input onFocus={el => scrambleAutoComplete(el)} label="Last name" className="medium-text-field" type="text" validationError="Please enter a last name" validations="isAlpha" maxLength={MAX_NAME_LENGTH} name="lastName" placeholder="Smith" />
            </div>
            <div className="eight columns">
              <Input layout="vertical" className="medium-text-field" value="" label="Email *" type="email" validations="isEmail" name="email" placeholder="joe@smith.com" />
              <br />
            </div>
            <div className="clear"></div>

            <div className="sixteen columns">
              <h5 className="small-margin-bottom">Newsletters:<small><br />Stay informed of the latest news and announcements from Bridge of Kindness.</small></h5>
              <Checkbox onClick={(el) => { }} rowClassName="override-label-width-no" value={typeof newsletter.newsAndAnnouncements === 'boolean' ? newsletter.newsAndAnnouncements : true} valueLabel="News and Announcements" name="newsAndAnnouncements" id="newsAndAnnouncements"></Checkbox>
              <br />
            </div>
            {NEW_PROFILE_ALERTS &&
              <div className="sixteen columns">
                <h5 className="small-margin-bottom">New Profile Email Alerts:<small><br />Subscribe to a periodical email digest of newly posted profiles of persons in need of assistance.</small></h5>
                <Checkbox onClick={(el) => { }} rowClassName="override-label-width-no" value={newsletter.dailyNewProfileAlert} valueLabel="Daily" name="dailyNewProfileAlert" id="dailyNewProfileAlert"></Checkbox>
                <Checkbox onClick={(el) => { }} rowClassName="override-label-width-no" value={newsletter.weeklyNewProfileAlert} valueLabel="Weekly (Every Saturday)" name="weeklyNewProfileAlert" id="weeklyNewProfileAlert"></Checkbox>
                <br />
              </div>
            }
            
            {/* <div className="sixteen columns">
              <h5 className="small-margin-bottom">Profile Waiting List:<small><br />Join our waiting list newsletter to be informed when there is availability to create a profile for a need.</small></h5>
              <Checkbox onClick={(el) => { }} rowClassName="override-label-width-no" value={newsletter.profileWaitingList} valueLabel="Profile Waiting List" name="profileWaitingList" id="profileWaitingList"></Checkbox>
              <br />
            </div> */}
            <button id="submit" type="submit" className="button color medium">Subscribe</button>

          </Form>
          {/* <div className="four columns">
            <iframe title="sendGrid" className="newsletter-iframe" src="https://cdn.forms-content.sg-form.com/5e9905d7-819a-11ea-bbe7-fa579b60e136" />
          </div> */}
        </div>
      </div>
    );
  }
}

export default Newsletter;