import React, { Component } from 'react';

import "../pages/css/support.css";

class Donate extends Component {
    render() {
        return (
            <div className="">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Donate to Bridge of Kindness</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sixteen columns">
                        <p><span className="bold">Your support</span> will help restore faith in humanity and assist with economic hardships. </p>
                        <p>Bridge of Kindness is a not-for-profit website to connect persons in need with persons willing to give.  We are supported by contributions from the community to pay for costs related to web development, hosting fees, marketing and advertising campaigns.</p>
                        <p>If you find hope, optimism, and faith through the mission of Bridge of Kindness, please consider sustaining our mission through a free-will donation. We provide help for the people in our very own communities; your action builds a stronger community.  </p>
                        <p>To donate, click the button below:</p>
                        <br />
                    </div>
                    <div>
                        <a className='donate-donorbox' href="https://donorbox.org/bridge-of-kindness-support-donation">Donate</a>
                    </div> 
                    {/* <div className="four columns">
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="57FE6DVEKZYP8" />
                            <input type="image" className="center donate-pp-spacing" src="/images/paypal-2.png" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" /><br />
                            <input type="image" className="small-margin-top" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>

                        <br /><br />
                    </div> */}
                </div>
            </div>
        );
    }
}
export default Donate;