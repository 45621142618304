import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faUserCircle, faEye, faComments, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import GA from '../utils/GoogleAnalytics';

class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.setShowHowItWorksModal = this.setShowHowItWorksModal.bind(this);
        this.setSlide = this.setSlide.bind(this);
        this.state = {
            showHowItWorksModal: false,
            slide: 0
        }
    }

    setSlide(inc) {
        const _this = this;
        let { slide } = _this.state;
        if (inc === 1 && slide < 2) {
            slide += 1;
        }
        if (inc === -1 && slide > 0) {
            slide -= 1;
        }
        _this.setState({ ..._this.state, slide });
    }

    setShowHowItWorksModal(showModal) {
        const _this = this;
        _this.setState({ ..._this.state, slide: 0, showHowItWorksModal: showModal });
        if (showModal) {
            GA.logModal('manage-profile/how-it-works');
        }
    }

    render() {
        const { showHowItWorksModal, slide } = this.state;
        const { classNameOverride } = this.props;

        const Questions = () => {
            return (
                <div className="how-it-works-any-questions"><span className="bold">Any questions?</span> Check out our <a href='/faq' className="underline">FAQs</a> or <a href='/request-assistance' className="underline">contact us</a> for assistance.</div>
            )
        }

        return (
            <div>
                <button className={classNameOverride ? classNameOverride : "button how-it-works-button color"} onClick={() => this.setShowHowItWorksModal(true)}><FontAwesomeIcon icon={faInfoCircle} size="lg" /> How it works</button>
                <Modal
                    center={true}
                    showCloseIcon={true}
                    open={showHowItWorksModal}
                    onClose={() => this.setShowHowItWorksModal(false)}>
                    <h2>How it Works</h2>
                    <div className="margin-top">
                        {slide === 0 ?
                            <div className="how-it-works-box" onClick={() => { window.location = '/request-assistance?from=how-it-works' }}>
                                <h3 className="center">1. Create a profile</h3>
                                <div className="center get-help-icon">
                                    <FontAwesomeIcon icon={faUserCircle} size="4x" />
                                </div>
                                <h5 className="show-if-large">Create a profile detailing the assistance you need.</h5>
                                <h5 className="show-if-small">Create a profile detailing the assistance you need.</h5>
                                <p className="how-it-works-explain">Our categories of need include <span className="bold italic">Food/Groceries, Financial Donations, Transportation</span> and more.<br /><br />Need help? Our <span className="bold">Community Care</span> team is ready to assist you in creating a profile.</p>
                                <Questions />
                            </div>
                            : ''}
                        {slide === 1 ?
                            <div className="how-it-works-box" onClick={() => { window.location = '/request-assistance?from=how-it-works' }}>
                                <h3 className="center">2. Profile gets viewed</h3>
                                <div className="center get-help-icon">
                                    <FontAwesomeIcon icon={faEye} size="4x" />
                                </div>
                                <h5 className="show-if-large">Contributors view profiles daily.</h5>
                                <h5 className="show-if-small">Contributors view profiles daily.</h5>
                                <p className="how-it-works-explain">Profiles are automatically placed in our catalog and will be viewed by contributors on a daily basis. Contributors have the ability to view profiles by geographic location and specific need.</p>
                                <Questions />
                            </div> : ''}
                        {slide === 2 ?
                            <div className="how-it-works-box" onClick={() => { window.location = '/request-assistance?from=how-it-works' }}>
                                <h3 className="center">3. Making a connection</h3>
                                <div className="center get-help-icon">
                                    <FontAwesomeIcon icon={faComments} size="4x" />
                                </div>
                                <h5 className="show-if-large">A person looking to give starts the conversation.</h5>
                                <h5 className="show-if-small">A person looking to give starts the conversation.</h5>
                                <p className="how-it-works-explain">When a contributing community member is ready to connect with a profile of someone in need, they will initiate a private message and next steps will be worked out between the profile owner and contributor. </p>
                                <Questions />
                            </div> : ''}
                        <div className="clear"></div>
                    </div>
                    {slide > 0 ? <div className="float-left margin-right">
                        <button type="button" className="button " onClick={() => {
                            this.setSlide(-1);
                        }}><FontAwesomeIcon icon={faArrowLeft} size="lg" /></button>
                    </div> : ''}
                    {slide < 2 ?
                        <div className="float-left">
                            <button type="button" className="button " onClick={() => {
                                this.setSlide(1);
                            }}><FontAwesomeIcon icon={faArrowRight} size="lg" /></button>
                        </div> : ''}
                    <div className="margin-left float-right">
                        <button type="button" className="button light" onClick={() => {
                            this.setShowHowItWorksModal(false);
                        }}>Close</button>
                    </div>
                    <div className="float-right">
                        <button type="button" className="button color" onClick={() => {
                            this.setShowHowItWorksModal(false);
                            window.location.href = '/request-assistance?from=hiw';
                        }}>Request assistance</button>
                    </div>

                </Modal>

            </div >
        )
    }
}

export default HowItWorks;
