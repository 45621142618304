import React, { Component } from 'react';

class DonateThankYou extends Component {
    render() {
        return (
            <div className="">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Donate to Bridge of Kindness</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sixteen columns">
                        <h3>Thank you for your donation!</h3>
                        <p>We appreciate your support of our mission to support persons in need.</p>
                        <p>Please be sure to <a href="/newsletter" className="underline">sign up for our newsletter</a> to stay informed of our latest news and events!</p>
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}
export default DonateThankYou;