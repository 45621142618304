import React from 'react';
import { NEED_OPTIONS } from '../../../constants';

const options = NEED_OPTIONS;

function TextDetailView({ tag, profile, separator }) {
    const textTag = 'text' + tag.replace(/^need/, '');
    const selectTag = 'select' + tag.replace(/^need/, '');
    const labels = {
        needDonationFinancial: "need of financial assistance",
        needDonationFoodSupplies: "need of food and/or supplies",
        needVolunteers: "need of volunteers",
        needErrands: "need of assistance with errands",
        needLawnCare: "need of assistance with lawncare",
        needPetDonation: "need of a pet donation",
        needTransportation: "need of transportation",
        needMentalSupport: "need of emotional support"
    }

    const optionsTitle = options[tag] ? options[tag].find(option => option.value === profile[selectTag]) : null;

    return (
        profile[tag] === true && (options[tag] || profile[textTag]) ? <div>
            <div className="need-section margin-top">
                <span className="bold">Details for {labels[tag]}:</span>
                {optionsTitle && options[tag] ? <p className="additional-info-text margin-top">{optionsTitle['label']}</p> : ''}
                {profile[textTag] ? <div className="additional-info-text margin-top">{profile[textTag].trim().split('\n').map((item, key) => {
                    return <div className="additional-info-text" key={key}>{item}<br /></div>
                })}</div> : ''}
            </div>
            {/* {separator ? <div className="headline">&nbsp;</div> : ''} */}
        </div >
            : ''
    )

}

export default TextDetailView;