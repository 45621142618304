import React from 'react';
import { Input } from 'formsy-react-components';
import scrambleAutoComplete from '../../utils/Scramble';

function ProfileFormContact({ profile, mode, onBlurCheckLocation, onChangeLocation, actualLocationText, locationError, componentRefs, nextButton }) {
    return (
        <div >
            <div className="three columns">
                <Input componentRef={(node) => { componentRefs(node, 'firstName') }} onFocus={el => scrambleAutoComplete(el)} label="First name" value={profile.firstName} className="text" validationError="Please enter a first name" type="text" validations="isAlpha" name="firstName" placeholder="Jane" required />
            </div>
            <div className="three columns">
                <Input componentRef={(node) => { componentRefs(node, 'lastInitial') }} onFocus={el => scrambleAutoComplete(el)} label="Last initial" value={profile.lastInitial} className={'last-initial-field'} type="text" validationError="Please enter a last initial" validations="isAlpha" maxLength="1" name="lastInitial" placeholder="S" required />
            </div>
            <div className="eight columns">
                <Input componentRef={(node) => { componentRefs(node, 'email') }} disabled={mode !== 'new' ? 'disabled' : ''} onFocus={el => scrambleAutoComplete(el)} layout="vertical" label="Account Email" value={profile.email} className="text long-text-field" type="email" validations="isEmail" validationError="Please enter a valid email" name="email" placeholder="joe@smith.com" required />
                <small className="form-text text-muted">Note: This email is -not- displayed to the public.</small>
                <label className="col-form-label">Please enter your city and state: (optional)</label>
                <Input componentRef={(node) => { componentRefs(node, 'locationText') }} onBlur={el => onBlurCheckLocation(el, this)}
                    onFocus={el => scrambleAutoComplete(el)}
                    onChange={el => onChangeLocation(el)} onKeyUp={el => onChangeLocation(el)} className="long-text-field" type="text" name="locationText" id="location" placeholder="City, State" hinttext="" value={actualLocationText} />
                {locationError ? <div className="alert alert-warning alert-dismissible fade show" dangerouslySetInnerHTML={{ __html: locationError }}></div> : ''}
                <label className="col-form-label">Please enter your zip code: (optional)</label>
                <Input componentRef={(node) => { componentRefs(node, 'zipCode') }} onFocus={el => scrambleAutoComplete(el)} className="short-text-field" type="text" name="zipCode" id="zipCode" validations={{
                    matchRegexp: /\d{5}/
                }} placeholder="93432" validationError="Please enter a valid Zip code" maxLength="5" hinttext="" value={profile.zipCode} />
                <small className="form-text text-muted">Note: This zip code is -not- displayed to the public.</small>
            </div>

            {nextButton ? <div className="eight columns"><br />
                <p className="margin-top"><button type="button" className="button wizard-button float-left" onClick={nextButton}>Continue</button></p>
            </div> : ''}
        </div>)
}

export default ProfileFormContact;