import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { isMobile, isTablet } from '../utils/Mobile';
import HowItWorks from '../../components/messages/HowItWorks';

import '../../pages/css/landing.css'

function Landing(props) {


    return (
        isTablet || isMobile ?
            <div className="mobile-landing-container">
                <div className="mobile-landing-container-outer" style={{ backgroundRepeat: 'repeat', backgroundSize: isTablet ? '100 auto' : 'auto 100%', backgroundImage: `url(${props.mobileImage})` }} >
                    <div className="mobile-landing-inner">
                        <h2>{props.heading}</h2>
                        {props.heading2 ? <h2>{props.heading2}</h2> : ''}
                        {props.cta ? <h4 className="landing-cta">{props.cta}</h4> : ''}
                        {props.cta2 ? <h4 className="landing-cta2">{props.cta2}</h4> : ''}
                        <a href={props.primaryButtonLink} ><button className="button mobile-landing-button-primary" type="button">{props.primaryButtonText}</button></a>
                        {/* {props.secondaryButtonLink ? <a href={props.secondaryButtonLink}><button className="button mobile-landing-button-secondary" type="button">{props.secondaryButtonText}</button></a> : ''} */}
                        {props.howitWorks && <HowItWorks />}
                        <p className="mobile-landing-copy-about"><span className="bold">Bridge of Kindness</span> helps to connect persons in need with persons who are looking for opportunities to give.<br /><br /><Link to="#home-body" className="underline">Learn more</Link> about our mission.</p>
                    </div>
                </div>
            </div > :
            <div className="landing-container">
                <div className="sixteen columns">
                    <div style={{ backgroundImage: `url(${props.image})` }} className="landing-image-profile  landing-container-center">
                        <div className="landing-copy">
                            {props.heading ? <h1>{props.heading}</h1> : ''}
                            {props.heading2 ? <h1>{props.heading2}</h1> : ''}
                            <p>&nbsp;</p>
                            {props.cta ? <h4 className="landing-cta">{props.cta}</h4> : ''}
                            {props.cta2 ? <h4 className="landing-cta2">{props.cta2}</h4> : ''}
                            <div className="landing-buttons-copy">
                                <a href={props.primaryButtonLink} ><button className="button landing-button-primary" type="button">{props.primaryButtonText}</button></a>
                                {props.secondaryButtonText ? <a href={props.secondaryButtonLink}><button className="button landing-button-secondary" type="button">{props.secondaryButtonText}</button></a> : ''}
                                {props.howitWorks && <HowItWorks />}
                                <div className="landing-copy-about"><span className="bold">Bridge of Kindness</span> helps to connect persons in need with persons who are looking for opportunities to give.<div className="landing-learn-more"><Link to="#home-body" className="underline">Learn more</Link> about our mission.</div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
    );
}

export default Landing;