import React from 'react';
import { Checkbox } from 'formsy-react-components';

function ProfileFormCategory({ profile, componentRefs, textBox, isMobile, previousStep, nextStep, previousButton, nextButton, checkFields }) {
    return (
        <div className="seven columns">
            <label className="col-form-label margin-bottom">Please select at least one of the following categories of need: </label>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needDonationFinancial') }} onClick={(el) => textBox(el, 'needDonationFinancial')} rowClassName="override-label-width-no" value={profile.needDonationFinancial} valueLabel="Financial assistance" name="needDonationFinancial" id="needDonationFinancial"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needDonationFoodSupplies') }} onClick={(el) => textBox(el, 'needDonationFoodSupplies')} rowClassName="override-label-width" value={profile.needDonationFoodSupplies} valueLabel="Food/Groceries" name="needDonationFoodSupplies" id="needDonationFoodSupplies"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needVolunteers') }} onClick={(el) => textBox(el, 'needVolunteers')} rowClassName="override-label-width" value={profile.needVolunteers} valueLabel="Volunteer(s)" name="needVolunteers" id="needVolunteers"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needErrands') }} onClick={(el) => textBox(el, 'needErrands')} rowClassName="override-label-width" value={profile.needErrands} valueLabel="Help with errand(s)" name="needErrands" id="needErrands"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needLawnCare') }} onClick={(el) => textBox(el, 'needLawnCare')} rowClassName="override-label-width" value={profile.needLawnCare} valueLabel="Help with lawn care" name="needLawnCare" id="needLawnCare"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needPetDonation') }} onClick={(el) => textBox(el, 'needPetDonation')} rowClassName="override-label-width" value={profile.needPetDonation} valueLabel="Help with a pet donation" name="needPetDonation" id="needPetDonation"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needTransportation') }} onClick={(el) => textBox(el, 'needTransportation')} rowClassName="override-label-width" value={profile.needTransportation} valueLabel="Transportation" name="needTransportation" id="needTransportation"></Checkbox>
            <Checkbox componentRef={(node) => { componentRefs(node, 'needMentalSupport') }} onClick={(el) => textBox(el, 'needMentalSupport')} rowClassName="override-label-width" value={profile.needMentalSupport} valueLabel="Emotional support" name="needMentalSupport" id="needMentalSupport"></Checkbox>
            {nextButton ? <div className="">
                <br /><p className="margin-top"><button type="button" className="button wizard-button-back" onClick={previousButton}>Back</button><button type="button" className="button wizard-button margin-left" onClick={nextButton}>Continue</button></p>
            </div> : ''}
        </div>)
}

export default ProfileFormCategory;