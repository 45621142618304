import React, { Component } from "react";
import { isMobile } from '../components/utils/Mobile';
import Faq from "react-faq-component";

import './css/faq.css';

const profileFaq = {
    title: "Profile FAQs",
    rows: [
        {
            title: "Will my personal details remain private in my profile?",
            content: `In order to help provide a safe meeting environment for both profile owners and givers/donors, we ask only for your <span className="bold">first name</span> and <span className="bold">last initial</span>.  This provides profile owners with a level of anonymity while also assisting donors with a means to identify the recipient of a donation.`
        },
        {
            title: "How can I be sure my profile is public and donors can see it?",
            content: `Bridge of Kindness uses a unique algorithm to match profiles with givers to ensure that all profiles have an equal opportunity to be seen.`,
        },
        {
            title: "How do I make an update to my profile? ",
            content:
                "Click <span class='underline'><a href='/manage-profile'>My Profile</a></span> in the navigation bar to gain access to your profile.",
        },
        {
            title: "How do I respond to a donor that wants to assist me? ",
            content: `We invite persons who give to include their contact information in their messages if they would like to be contacted.  Feel free to contact a donor with the contact details that they provide.`,
        },
        {
            title: "How long will my profile remain visible?",
            content: "Bridge of Kindness uses a unique algorithm to match providers with givers evenly within a cycle of total views and amount of time.  To ensure fairness, profiles are scheduled for suspension after a period of time and number of views.  At this time, you will be contacted by email to confirm if your profile is still needed.  To keep your profile online for an additional cycle, simply follow the instructions provided in the notice of suspension and your profile will remain active for an additional cycle.  Repeat this process until the need is no longer required.",
        },
        {
            title: "Who do I contact to troubleshoot a problem with my profile?",
            content:
                "To troubleshoot an issue with your profile, please use our <span class='underline'><a href='/contact'>contact form</a></span>.",

        }
    ],
};

const giveFaq = {
    title: "Giving FAQs",
    rows: [
        {
            title: "Do I give Bridge of Kindness money or the person in need?",
            content: `All financial transactions are handled between the donor and the profile owner directly.  To donate to a profile owner, use their provided Cash App, PayPal or Venmo id to make your donation directly. We encourage you to use the 'Send a message' function on a profile page to indicate to a profile owner if a donation will be made.`,
        },
        {
            title: "How do I get in touch with someone whose profile I am interested in helping?",
            content:
                "When viewing a profile, use the 'Send Message' button to send a personalized message directly to the profile owner's inbox.  Remember, if you wish for the profile owner to contact you - you must <span class='underline'>provide your contact information</span> in your message.",
        },
        {
            title: "Is my personal information kept private?",
            content: `Bridge of Kindness utilizes best practices in security and <a class='underline' href='http://www.bridgeofkindness.org/privacy'>privacy</a>. Your information is not shared.  Any information you send to the profile owner can only be viewed by the profile owner.`,
        },
        {
            title: "How do I know my donation is going to the profile I select and not to someone else’s profile?",
            content: `If you wish to send a donation to a profile owner, be sure to use the Venmo, Cash App or PayPal id they have provided on their profile page.`,
        },
        {
            title: "How do I know if it is safe to give to someone in need?",
            content: `We have created an environment designed to allow two strangers to meet in a safe and and secure platform.  With that being said, we encourage the use of discernment and good judgement when communicating or considering a donation for a profile owner.  We aim to simulate a scenario of a giver meeting a stranger in need.  We welcome <span class='underline'><a href='/contact'>any feedback</a></span> to help make our environment safer and easy to use.`,
        },

        {
            title: "Who do I contact to troubleshoot a problem with the website? ",
            content:
                "To report an issue with the website, please use our <span class='underline'><a href='/contact'>contact form</a></span> to reach out!",
        }
    ],
};


class About extends Component {

    render() {
        return (
            <div className="container">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Frequently Asked Questions</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="eight columns">
                        <div className="faq-box">
                            <Faq data={profileFaq} styles={{
                                rowContentColor: "grey"
                            }} />
                        </div>
                    </div>
                    <div className="eight columns">
                        {isMobile ? <div className="headline">&nbsp;</div> : ''}
                        <div className="faq-box">
                            <Faq data={giveFaq} styles={{
                                rowContentColor: "grey"
                            }} /></div>
                    </div>
                    <div className="sixteen columns">
                        <br /><br /><br />
                        <h5 className="center">Can't find the answer to your question? Please use our <span className="underline"><a href="/contact">Contact form</a></span></h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;