import React, { Component } from "react";
import { isMobile } from "../components/utils/Mobile";

import "../pages/css/missions.css";

class Missions extends Component {
  constructor(props) {
    super(props);
    this.GA = props.GA;
  }

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>Missions</h2>
              <div id="bolded-line"></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="sixteen columns">
            <div className="margin-left">
              <p>
                One of the distinctive and admirable traits of Bridge of
                Kindness is the monthly mission it undertakes. Every month, with
                unwavering dedication, we strive to connect individuals who are
                in need with those who are seeking an opportunity to give and
                make a positive impact in their lives.
              </p>

              <p>
                Through the outreach of Bridge of Kindness missions, we aim to
                provide substantial and meaningful assistance to individuals
                facing various challenges. Our efforts encompass a wide range of
                areas, including:
              </p>
              <ul className="list-style-square">
                <li>
                  Addressing food insecurity by ensuring access to nutritious
                  meals and supporting local food banks.
                </li>
                <li>
                  Meeting clothing needs by providing essential clothing items
                  to individuals and families, helping them stay warm and
                  comfortable.
                </li>
                <li>
                  Offering personal and household goods to those who require
                  assistance, enhancing their quality of life.
                </li>
                <li>
                  Extending care to pets by providing pet food, supplies, and
                  veterinary care to ensure their well-being.
                </li>
                <li>
                  Assisting individuals with utility bills, alleviating
                  financial burdens and ensuring access to essential services.
                </li>
                <li>
                  Supporting new mothers with postnatal supplies, contributing
                  to their well-being and the health of their newborns.
                </li>
              </ul>
              <p>
                By focusing on these areas, Bridge of Kindness aims to make a
                positive and lasting difference in the lives of those who are
                facing challenges and create a stronger, more compassionate
                community. Together, we can bring hope and support to those in
                need.
              </p>
              <p>
                Our next mission is <a className="underline" href="https://blog.bridgeofkindness.org/winter-2023-mission-happy-acres/"><strong>Winter of 2023 - Happy Acres</strong></a>.  Click <a className="underline" href="https://blog.bridgeofkindness.org/winter-2023-mission-happy-acres/">here</a> to learn more.
                </p>
                <p>Please subscribe to our <a className="underline" href='/newsletter'>newsletter</a> to be informed of new missions and opportunities to assist others in need.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Missions;
