import React, { Component } from 'react';

class Privacy extends Component {
    render() {
        return (
            <div className="">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Online Safety and Privacy Guidelines</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>                <div className="container">
                    <div className="sixteen columns">

                        <p>Bridge of Kindness recognizes that ensuring privacy and promoting safety are requisite measures needed for successful fulfillment of its vision and mission. These measures entail a secure online experience for profile holders and potential donors, including full transparency about our <a className="underline" href='/privacy'>privacy policy</a>. For more detail on our privacy and general online safety guidelines, please see below.</p>

                        <div className="headline">
                            <h3>Bridge of Kindness Online Safety Guidelines</h3>
                        </div>

                        <p>Bridge of Kindness urges all platform end-users, both profile holders and donors, to follow general online safety guidelines. While this platform is designed for all support and interaction to take place with relative anonymity, some profile holders or donors may feel compelled to provide more personal information, as well as to connect in person. If so, please keep the following safety guidelines in mind:</p>
                        <ul className="list-style-square">
                            <li>Never provide personal information that can be exploited, including your birthdate and other sensitive data</li>
                            <li>Always meet in a public place, and ensure that you tell at least one other person where you are going and who you are meeting</li>
                            <li>Trust your instincts</li>
                        </ul>

                        <p>For more information about personal safety online, check out these resources:</p>

                        <div className="small-margin-top"><a href='http://getsafeonline.org' className="underline" target="_blank">http://getsafeonline.org</a></div>
                        <div className="small-margin-top"><a href='http://wiredsafety.org' className="underline" target="_blank">http://wiredsafety.org</a></div>
                        <div className="small-margin-top"><a href='http://www.onguardonline.gov/' className="underline" target="_blank">http://www.onguardonline.gov</a></div>


                        <div className="headline">
                            <h3>Bridge of Kindness Privacy Guidelines</h3>
                        </div>

                        <p>Bridge of Kindness highly values the privacy of both profile holders and donors, which is critical for facilitating the organic experience of benefitting from an online Samaritan, becoming an online Samaritan. Therefore, Bridge of Kindness has taken the following measures to ensure a secure, private experience for both end-users:</p>
                        <ul className="list-style-square">
                            <li>Profile holders will only have their first name and last initial visible; the full surname will not be visible to anyone browsing profiles.</li>
                            <li>Profile holders have full discretion over the contact information released to donors</li>
                            <li>Some profile holders may provide their Venmo or PayPal user name, for the purpose of receiving direct financial assistance</li>
                            <li>Donors will be able to communicate as anonymously as they’d like. While encouraged to contact a profile holder directly through Bridge of Kindness’s secure system, they are under no obligation to release any personal information to a profile holder.</li>
                            <li>Profile holders and donors may also <a className="underline" href='/contact'>reach out</a> to the Bridge of Kindness team at any point in time with questions regarding privacy and other policies</li>
                        </ul>


                    </div>
                </div>
            </div>
        );
    }
}
export default Privacy;