import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';

import './pages/css/common.css';
import './pages/css/formsy.css';
import './pages/css/tooltip.css';
import './pages/css/form-checkbox.css';

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Home from './pages/Home';
import PostProfile from './pages/PostProfile';
import ManageProfile from './pages/ManageProfile';
import ViewProfile from './pages/ViewProfile';
import ErrorProfile from './pages/ErrorProfile';
import GetProfile from './pages/GetProfile';
import Contact from './pages/Contact';
import About from './pages/About';
import Team from './pages/Team';
import Volunteer from './pages/Volunteer';
import Missions from './pages/Missions';
import Faq from './pages/Faq';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Support from './pages/Support';
import SupportThankYou from './pages/SupportThankYou';
import Newsletter from './pages/Newsletter';
import Activate from './pages/Activate';
import GetHelp from './pages/GetHelp';
import PrivacySafety from './pages/PrivacySafety';
import Error404 from './pages/Error404';

// Campaigns
import Campaign from './pages/Campaign';

const history = createBrowserHistory();

const { REACT_APP_FACEBOOK_PIXEL_ID } = require('../constants')
ReactPixel.init(REACT_APP_FACEBOOK_PIXEL_ID)
ReactPixel.pageView();

history.listen(location => {
  ReactPixel.pageView();
});

class App extends Component {
  constructor(props) {
    super(props)

    // if (isMobile) {
    //   window.location.href = '/mobile-coming-soon.html'
    //   return;
    // }
  }


  render() {

    const { GA } = this.props;

    const Content = () => (<div><div id="wrapper">
      {/* <div className="covid display-none">
              This is a banner.
        </div> */}
      <Header />
      <Switch>
        <Route exact path='/' render={(props) => <Home GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route exact path='/logo2' render={(props) => <Home GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/contact' render={(props) => <Contact GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/about' render={(props) => <About GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/story' render={(props) => <About GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/team' render={(props) => <Team GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/missions' render={(props) => <Missions GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/volunteer' render={(props) => <Volunteer GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/faq' render={(props) => <Faq GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/terms' render={(props) => <Terms GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/privacy' render={(props) => <Privacy GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/support' render={(props) => <Support GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/donate' render={(props) => <Support GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/support-thankyou' render={(props) => <SupportThankYou GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/newsletter' render={(props) => <Newsletter GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/activate/:accessKey' render={(props) => <Activate GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/error-profile/' render={(props) => <ErrorProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/manage-profile/:accessKey/:confirmed' render={(props) => <ManageProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/manage-profile/:accessKey' render={(props) => <ManageProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/manage-profile' render={(props) => <ManageProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/post-profile' render={(props) => <PostProfile GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/get-help' render={(props) => <GetHelp GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/request-assistance' render={(props) => <GetHelp GA={GA} ReactPixel={ReactPixel} {...props} />} />

        <Route path='/reload-get-profile/:error' render={(props) => <GetProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/get-profile/:error' render={(props) => <GetProfile GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/get-profile' render={(props) => <GetProfile GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/view-profile/:viewAccessKey/:self' render={(props) => <ViewProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/p/:viewAccessKey' render={(props) => <ViewProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/view-profile/:viewAccessKey' render={(props) => <ViewProfile GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route path='/view-profile' render={(props) => <ViewProfile GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/view-profiles' render={(props) => <ViewProfile GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/view-profiles-start' render={(props) => <ViewProfile getNextProfileNow={true} GA={GA} ReactPixel={ReactPixel} {...props} />} />
        <Route path='/privacy-safety' render={(props) => <PrivacySafety GA={GA} ReactPixel={ReactPixel} {...props} />} />

        <Route path='/campaign/:campaignId' render={(props) => <Campaign GA={GA} ReactPixel={ReactPixel}  {...props} />} />
        <Route component={Error404} />
      </Switch>
    </div>
      <Footer />
    </div>)

    const Wrapper = () => (
      <Content></Content>
    )
    return (
      <Switch>
        <Wrapper />
      </Switch>
    );
  }
}

export default App;