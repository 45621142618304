import React, { Component } from 'react';
import Profile from '../components/profile/ProfileForm/ProfileForm';
import cookie from 'react-cookies'
import { Redirect } from 'react-router-dom';

import { ACCEPT_NEW_PROFILES } from '../../constants';

import { isMobile, isTablet } from '../components/utils/Mobile';

class PostProfile extends Component {
  constructor(props) {
    super(props);
    this.updateStep = this.updateStep.bind(this);
    this.state = {
      step: 1
    }

    this.GA = props.GA;
    this.ReactPixel = props.ReactPixel;

    cookie.remove('accessKey', { path: '/' })
  }

  updateStep(step) {
    const _this = this;
    _this.setState({ ..._this.state, step });
  }

  render() {

    if (!ACCEPT_NEW_PROFILES) {
      return <Redirect to="/request-assistance" />
    }
    const { step } = this.state;
    const { GA, ReactPixel } = this.props;

    return (
      <div className={isMobile && !isTablet ? 'fix-margin-mobile' : ''}>
        <div className="container">
          {step === 1 ?
            !isMobile ?
              <div className="eight columns">
                <br />
                <div className="large-notice">
                  <div className="seven columns">
                    <h5 className="post-profile-welcome ">Request assistance</h5>
                    <img className="light-border post-profile-image" src='/images/stockvectors/post_profile.png' />
                    <p className="margin-top"><span className="bold">Everyone needs support at times</span>, especially in the time of a crisis. Bridge of Kindness aims to connect those who are in need with people who have the resources to assist or provide support with the goal of uplifting communities and spreading kindness.</p>
                  </div>
                  <div className="clear"></div>
                  <div className="seven columns">
                    <h5>How it works:</h5>
                    <ul className="list-style-square">
                      <li>Use our safe and secure platform to <span className="bold">create a profile</span>, detailing your exact need(s).</li>
                      <li>You will have a dedicated profile page that shares the details of your need.</li>
                      <li>Once someone selects your profile, you may receive a private message that helps you both to set up next steps.</li>
                      <li>If you wish to be able to receive donations, please enter your <span className="bold">PayPal</span>, <span className="bold">Cash App</span> and/or <span className="bold">Venmo</span> id in your profile where indicated.</li>
                      <li>Once you have created your profile, you will be sent a link to access your dashboard. From your dashboard, you can modify your profile at any time and review messages.</li>
                    </ul>
                  </div>
                  <div className="clear"></div>

                </div>
              </div>
              : <div>
              </div>
            : ''}
          <div className={step === 1 ? isTablet ? "sixteen columns" : "seven columns" : "ten columns"}>
            {step === 1 ?
              isTablet ? <div><div className="four columns">&nbsp;</div> <div className="eight columns profile-frame"><Profile GA={GA} ReactPixel={ReactPixel} updateStep={this.updateStep} mode="new" /></div> <div className="four columns">&nbsp;</div></div> :
                <div className="profile-frame"><Profile GA={GA} ReactPixel={ReactPixel} updateStep={this.updateStep} mode="new" /></div> :
              <div className="mobile-post-profile-thank-you">
                <br />
                <h3 className="post-profile-welcome">Thank you!</h3>
                <br></br>
                <h2><span className="bold">Your profile is now online.</span></h2>
                <br /><h4>Please <span className="underline">check your email</span> and follow the instructions to activate your profile.  <i>Please note that PayPal, Venmo and CashApp details will not be publicly visible until the profile is activated.</i></h4>
                <br />
                <div className="notification notice margin-top">

                  <div className="nudge-margin-top">
                    <p><span className="underline">Didn't receive an email?</span></p>
                    <ul className="list-style-square">
                      <li>Gmail users, please check your "Spam" folder.</li>
                      <li>Hotmail email users, please check your "Junk email".</li>
                      <li>Yahoo users, please check your "Bulk Mail" folder.</li>
                    </ul>
                    <p> In all of these cases, please press the 'Not Spam' confirmation to ensure future email from Bridge of Kindness is received.</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <p></p>
              </div>
            }
          </div>
        </div>
      </div >
    );
  }
}
export default PostProfile;