import React from 'react';
import { Textarea } from 'formsy-react-components';
import { MAX_TEXTAREA_SIZE } from '../../../../constants';
import Steps from './Steps';

function ProfileFormIntro({ profile, isMobile, needLength, nextStep, previousStep, previousButton, nextButton, checkNeedLength, componentRefs }) {
    // <span className={needLength < MAX_TEXTAREA_SIZE ? 'margin-left textarea-count' : 'margin-left textarea-count-max'}>{needLength}/{MAX_TEXTAREA_SIZE} characters</span>

    return (
        <div className="eight columns">
            <label className="col-form-label">Introduction / Your story (optional)</label>
            <Textarea
                value={profile.need}
                name="need" placeholder="Tell givers more about you and/or your circumstances."
                validations={`maxLength:${MAX_TEXTAREA_SIZE}`}
                validationError={`Introduction should be ${MAX_TEXTAREA_SIZE} characters or less`}
                maxLength={MAX_TEXTAREA_SIZE}
                onKeyUp={(el) => checkNeedLength(el)}
                onChange={(el) => checkNeedLength(el)}
                componentRef={(node) => { componentRefs(node, 'need') }}
                rows="8"
            ></Textarea>
            <span className="hint-text">Note: Adding a phone number, email or other contact information is -not- recommended.</span>
            {nextStep ? <div className="">
                <br /><p className="margin-top"><button type="button" className="button wizard-button-back" onClick={previousButton}>Back</button><button type="button" className="button wizard-button margin-left" onClick={nextButton}>Continue</button></p>
            </div> : ''}
        </div>)
}

export default ProfileFormIntro;