import React, { Component } from 'react';

class Terms extends Component {
    render() {
        return (
            <div className="">
                <div className="container">
                    <div className="sixteen columns">
                        <div id="page-title">
                            <h2>Bridge of Kindness Terms of Use</h2>
                            <div id="bolded-line"></div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="sixteen columns">

                        <p>These Terms of Use (the “Agreement” or “Terms”) govern your access to and participation in the Bridge of Kindness (“BOK”) online platform (the “Service”). By registering for, accessing, and using the Service, you agree to all terms set forth in this Agreement. If you do not accept and agree to be bound by all of the terms of this Agreement, do not use the Service. </p>

                        <h3>About the Service</h3>

                        <p>The Service is an online platform that connects people in need with people who are able to help, either through donations (monetary or goods) or through volunteering. People in need (the “Recipient”) post profiles describing their situation and hardships they are currently facing, as well as a description of the assistance that they are seeking. People who are willing to provide support or assistance (the “Donor”) are able to send direct messages to the Recipient, via the Service, to offer assistance. The Service is offered free of charge.</p>

                        <h3>Your Responsibilities and Representations</h3>
                        <p>You are responsible for your account and anything that happens while you are signed in to or using your account.</p>
                        <ul className="list-style-square">
                            <li> <em>Account Security</em> When you sign up for the Service, you will create a personalized account which includes a unique email address to access the Service. You are responsible for maintaining the security of your account, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You agree to notify BOK immediately of any unauthorized use of your account, or any other breaches of security. We will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your computer, mobile device, or other computing device and/or account.</li>

                            <li> <em>Profile Content</em> Recipients are responsible for the accuracy of their profiles. BOK does not conduct any investigations with respect to the accuracy of Recipients’ profiles and makes no representations to Donors with respect to the accuracy of any Recipient’s profile.</li>

                            <li> <em>Compliance with Laws</em> You represent and warrant that: (i) you have the authority to, and are of legal age in your jurisdiction to, bind yourself to this Agreement; (ii) your participation in the Service will be solely for purposes that are permitted by this Agreement; (iii) your participation in the Service will not infringe or misappropriate the intellectual property rights of any third party; and (iv) your participation in the Service will comply with all local, state and federal laws, rules, and regulations.</li>

                            <li> <em>Representations Regarding Identity. </em>You represent that all information regarding your identity is accurate. If you, as a Recipient, are no longer in need of assistance, you are responsible for updating your profile. If we learn that you have misrepresented your identity or needs, we will terminate your account immediately and reserve the right to inform any Donors of the inaccuracies.</li>

                            <li> <em>Users Must Be Over Age 18</em> You represent that you are over the age of 18. We do not target our content to children or teenagers under 18, and we do not permit any users under 18 to use the Service. If we learn of any user under the age of 18, we will terminate that user’s account immediately.</li>
                        </ul>
                        <h3>Use and Conduct Restrictions</h3> <p>You are allowed to participate in the Service as long as you abide by the following rules:</p>

                        <ul className="list-style-square">
                            <li> <em>Prohibited Content</em> You agree that you will not under any circumstances transmit any content that</li>
                            <ul className="list-style-circle">
                                <li> is unlawful or promotes unlawful activity;</li>

                                <li> defames, harasses, abuses, threatens, or incites violence towards any individual or group;</li>

                                <li> is pornographic, discriminatory, or otherwise victimizes or intimidates an individual or group on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>

                                <li> is spam, is machine- or randomly-generated, constitutes unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;</li>

                                <li> contains or installs any viruses, worms, malware, Trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party;</li>

                                <li> infringes on any proprietary right of any party, including patent, trademark, trade secret, copyright, right of publicity, or other rights;</li>

                                <li> impersonates any person or entity, including any of our employees or representatives; or</li>

                                <li> violates the privacy of any third party.</li>
                            </ul>

                            <li> <em>No Liability for User Interactions</em> Any liability, loss or damage that occurs as a result of any user interactions is solely your responsibility.</li>

                            <li> <em>Right to Terminate Accounts</em> We have the right (though not the obligation) to, in our sole discretion, determine whether or not any user conduct is appropriate and complies with these Terms, or terminate or deny access to and use of the Service to any user for any reason, with or without prior notice.</li>

                            <li> <em>BOK May Remove Content</em> We have the right (though not the obligation) to, in our sole discretion, determine whether or not any profile is appropriate and complies with these Terms, or refuse or remove any content, in our opinion, is in any way harmful, inappropriate, or objectionable.</li>
                        </ul>
                        <h3>Email Communications</h3>
                        <p>We use email and electronic means to stay in touch with our users. You consent to receive communications from BOK in an electronic form via the email address you submitted when registering for your Service account</p>

                        <h3>Disclaimer of Warranties</h3>
                        <p>We provide the Service without any promises or guarantees.</p >
                        <ul className="list-style-square">
                            <li>We provide access to and host the Service “as is,” without warranty of any kind. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SERVICE INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT.</li>

                            <li>Specifically, BOK makes no representation or warranty that the information in the Service and exchanged through messages is true and correct; that the Service will meet your requirements; that access to the Service will be available at any particular time or location; that the Service will function in an uninterrupted manner or be secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your use of the Service. Some jurisdictions limit or do not permit disclaimers of warranty, so this provision may not apply to you.</li>

                        </ul>

                        <h3>No Liability</h3>
                        <p>IN NO EVENT SHALL WE BE RESPONSIBLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT(INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY. </p >

                        <h3>Release and Indemnification</h3>
                        <p>You agree to indemnify and hold BOK harmless from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Service, including but not limited to your violation of this Agreement.If you have a dispute with one or more users, you release us from claims, demands and damages(actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. </p >

                        <h3>Modification of Terms</h3>
                        <p>BOK may, in its sole discretion, amend this Agreement from time to time. We will provide notification to users of material changes to this Agreement (i) by sending a notice to the primary email address specified in your account, which will take effect immediately upon our sending of this email, and/or (ii) through our website at least 30 days prior to the change taking effect. Non-material changes to this Agreement will take effect immediately. We encourage visitors to frequently check this page for any changes to this Agreement. Your continued use of the Service after the effective date of a revised version of this Agreement constitutes your acceptance of such terms. </p>

                        <h3>Term; Account Deletion or Suspension</h3>
                        <p>This Agreement begins on the date you create your account continues as long as you have an account with BOK, regardless of whether you are actively using the Service.</p>
                        <p>You may delete your account at any time. BOK may suspend, disable, or delete your account (or any part thereof) or block or remove your profile if BOK determines that you have violated any provision of this Agreement.</p>
                        <p>All provisions of this Agreement which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

                        <h3>Governing Law; Venue</h3>
                        <p>This Agreement shall be construed, interpreted and governed by the laws of the State of California, USA, without regard to the conflicts of law provisions thereof. Any action or proceeding by either party to enforce this Agreement shall be brought in any state or federal court located in the State of California, Contra Costa County. The parties hereby irrevocably submit to the exclusive jurisdiction of these courts and waive the defense of inconvenient forum to the maintenance of any action or proceeding in such venue. </p>

                        <h3>Severability</h3>
                        <p>If any part of this Agreement is found invalid or unenforceable by a court of competent jurisdiction, such invalidity or unenforceability shall not affect the remaining provisions of this Agreement.The parties hereby jointly request that any such provision be construed by modifying its scope so as to be enforceable to the fullest extent of the law.</p >

                        <h3>Entire Agreement</h3>
                        <p>These Terms, together with the <span className="underline"><a href='/privacy'>Privacy Policy</a></span> at Bridge of Kindness, represent the complete and exclusive statement of the agreement between you and BOK.</p>
                    </div >
                </div>
            </div >
        );
    }
}
export default Terms;