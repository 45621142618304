import React from 'react';
import { Checkbox } from 'formsy-react-components';
import ProfileView from '../ProfileView';
import Alert from '../../messages/Alert';

function ProfileFormSubmit({ formNoValidate, mode, profile, isMobile, previousButton, nextButton }) {
    return (
        <div>
            {previousButton ? <Alert class='success' text="Please review your profile information:" /> : ''}
            {previousButton ? <div className="preview-profile">
                <div className={isMobile ? "preview-profile-inner" : ""}>
                    <ProfileView profile={profile} slim={true} />
                    <br />
                    <div className="clear"></div>
                </div>
            </div> : ''}

            {mode === 'new' ?
                <div className="seven columns">
                    <div className="terms-checkbox">
                        <Checkbox rowClassName="override-label-width-no" valueLabel={<div>I agree to website <a href='/terms' target='terms-privacy' className='underline'>terms and conditions</a> and <a href='/privacy' target='terms-privacy' className='underline'>privacy policy</a>.</div>} name="terms" id="terms" validationError="You must agree to website terms and conditions and privacy policy to continue." required></Checkbox>
                    </div>
                </div>
                : ''}
            <div className="seven columns">
                {/* {this.mode === 'new' ? <button id="cancel" type="button" className="button light medium" onClick={() => { this.redirect('/') }}>Cancel</button> : ''} */}

                {previousButton ? <div className="">
                    <p className="margin-top"><button type="button" className="button wizard-button-back" onClick={previousButton}>Back</button><button id="submit" type="submit" className="button wizard-button margin-left">Create profile</button></p>
                </div> :
                    <div>
                        <div className="clear"></div><br />
                        <button formNoValidate id="submit" type="submit" className="button color medium margin-top">{mode === 'new' ? 'Create' : 'Save'} profile</button>
                    </div>}
            </div>
        </div >)
}

export default ProfileFormSubmit;