import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cookie from 'react-cookies'
import { Form, Input } from 'formsy-react-components';

import Alert from '../components/messages/Alert';

const errorMessages = {
  'mp-e3': 'Your profile session has expired.',
  'mp-e4': 'There was an error accessing your profile',
  'mp-e5': 'Your profile session has expired.',
  'mp-e6': 'There was an error accessing your profile and messages.',
  'ac-e2': 'There was an error activating your profile.',
  'ac-e3': 'There was an error activating your profile.',
  'mp-logout': 'You have successfully logged out of your profile',
  'request': 'If a profile is found matching this email, instructions to access the profile will be provided via email.'
}

class GetProfile extends Component {
  constructor(props) {
    super(props);

    this.setError = this.setError.bind(this);
    this.submit = this.submit.bind(this);
    this.redirect = this.redirect.bind(this);

    this.GA = props.GA;

    this.state = {
      apiError: props.match.params.error && errorMessages[props.match.params.error] ? errorMessages[props.match.params.error] : '',
      redirect: null,
      email: ''
    };

    // clear cookies
    cookie.remove('accessKey', { path: '/' })

  }

  setError(error, fade) {
    const _this = this;
    _this.setState({ ..._this.state, apiError: error });
    if (fade) {
      setTimeout(() => {
        _this.setState({ ..._this.state, apiError: '' });
      }, 5000)
    }
  }

  submit(profile, resetForm) {
    const _this = this;

    if (!profile.email) {
      _this.setError("Please enter your email address.", true);
      return;
    }

    const request = {
      url: '/api/requestprofile',
      method: 'post',
      data: {
        email: profile.email
      }
    }

    axios(request)
      .then(function (response) {
        //console.log('ok', response)
      })
      .catch(function (error) {
        //console.log('error', error)
      }).finally(() => {
        _this.setError('If a profile is found matching this email, we just sent a temporary login link.  Please check your email inbox.')
        resetForm()
      });
  }

  redirect(url) {
    const _this = this;
    _this.setState({ ..._this.state, redirect: url });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { apiError } = this.state;
    const { GA } = this.props;

    return (
      <div className="container">
        <div className="container">
          <div className="sixteen columns">
            <div id="page-title">
              <h2>My Profile</h2>
              <div id="bolded-line"></div>
            </div>

          </div>
        </div>
        <div className="container">
          <div className="eight columns">
            <Form
              onSubmit={this.submit}
            >
              <div>
                {apiError ? <div>{GA.logEvent({ category: 'Get Profile', action: 'API Error', label: apiError })}<Alert class='warning' text={apiError} /></div> : ''}
                <p>Please enter your email address below to get access to your profile.</p>
                <Input layout="vertical" className="long-text-field" value="" label="Email" type="email" validations="isEmail" name="email" placeholder="joe@smith.com" />
              </div>
              <br />
              <button id="submit" type="submit" className="button color medium">Get profile</button>
            </Form>
          </div>
        </div></div>

    );

  }
}
export default GetProfile;